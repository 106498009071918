body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:#A9A9A9 !important;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
 color:#A9A9A9 !important;
 opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
 color:#A9A9A9 !important;
 opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color:#A9A9A9 !important;
}
::-ms-input-placeholder { /* Microsoft Edge */
 color:#A9A9A9 !important;
}

::placeholder { /* Most modern browsers support this now. */
 color:#A9A9A9 !important;
}

.form-control{
  font-size: 16px !important;
}