.tools {
    margin: 0 2px;
    cursor: pointer;
}
.landingStatus {
    margin-top: 5px;
}
@media (max-width: 1350px) {
    .table th, .table td {
        padding: 0.75rem !important;
    }
    .card-header {
        padding: 1.5rem 0.8rem;
    }
    .dropdown-menu {
        left: -30px !important;
    }
}

@media (max-width: 1150px) {
    .table tr:first-child {
        display: none;
    }
    .quiz-direct-url {
        display: none;
    }
}

@media (max-width: 1010px) {
    .quiz-creation-date {
        display: none;
    }
}

.lead-action {
    margin-right: -20px;
    margin-left: -20px;
}

@media (max-width: 1450px) {
    .lead-action-span {
        display: none !important;
    }
}

@media (max-width: 1430px) {
    .steps {
        margin-left: -1rem;
    }
}

@media (max-width: 1350px) {
    .steps {
        margin-left: -2rem;
    }
}

@media (max-width: 1250px) {
    .steps {
        margin-left: -3rem;
    }
}

@media (max-width: 1170px) {
    .step6 {
        /* display: none; */
    }
    .step6-min {
        display: block !important;
    }
}

@media (max-width: 1060px) {
    .step5 {
        /* display: none; */
    }
    .step5-min {
        display: block !important;
    }
}

@media (max-width: 1000px) {
    .step4 {
        /* display: none; */
    }
    .step4-min {
        display: block !important;
    }
}

/*template-responsive*/

@media (max-width: 1650px) {
    .template-responsive {
        max-width: 50% !important;
        flex: 0 0 50% !important;
    }
    .templates .row .template-responsive .card{
        height: 360px !important;
    }
}

.question-type {
    /*display: 'inline-block', width: '60%'*/
    display: inline-block;
    width: 60%;
}

@media (max-width: 1450px) {
    .question-type {
        display: none !important;
    }
}

/*responsive-packages*/
@media (max-width: 1560px) {
    .responsive-packages {
        max-width: 50% !important;
        flex: 0 0 50% !important;
    }
}
