.commonBtn {
    margin: 10px;
}
select {
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    background-image: url(./arrow-down.svg);
    background-repeat: no-repeat;
    background-position: 96% 50%;
    cursor: pointer;
}
.profile-page{
    .danger-content{
        background: #FFFFFF;
        box-shadow: 0px 0px 16px rgba(196, 196, 196, 0.19);
        display: flex;
        align-items: center;
        height: 53px;
        border-left: 5px solid #FF8E00;
        margin-bottom: 30px;
        margin-top: 0;
        p{
            margin: 0;
            color: #676767;
            font-size: 14px;
        }
        i{
            color: #FF8E00;
            margin: 0 15px 0 5px;
            font-size: 18px;
        }
        b{
            color: #00B6FF;
            font-weight: 400;
            text-decoration: underline;
        }
    }
    .btn-group{
        display: flex;
        justify-content: space-between;
        width: 40%;
        max-width: 550px;
        min-width: 450px;
        button{
            color: black;
            background-color: white;
            border-radius: 0;
            padding: 8px 16px;
            border: none;
            &:hover{
                background-color: #d6d6d6;
            }
            &.active{
                background-color: black !important;
                color: white;
            }
        }
    }
    .profile-form{
        width: 90%;
        margin-top: 30px;
        max-width: 450px;
        min-width: 300px;
        .card-content{
            .btn-create{
                padding: 9px 24px;
            }
            .row{
                margin: 0;
            }
            .form-group{
                select{
                    background-color: #F8F8F8;
                    border-radius: 0;
                    border-color: #F8F8F8;
                    color: black;
                }
                label{
                    text-transform: uppercase;
                    color: #545454;
                    font-size: 15px;
                    margin-left: 2px;
                }
                input{
                    background-color: #F8F8F8;
                    border-radius: 0;
                    border-color: #F8F8F8;
                    color: black;
                }
            }
        }
    }
}

.profile-container{
    display: flex;
    justify-content: space-between;
    >.profile-form-container{
        width: 35%;
    }
    .profils-info{
        width: 65%;
    }
}