.swatch {
    background: #fff;
    border-radius: 0px;
    display: inline-block;
    cursor: pointer;
    border: 1px solid #E0E0E0;
}

.color {
    width: 28px;
    height: 28px;
    border-radius: 0px;
}

.popoverColorPicker {
    position: absolute;
    z-index: 666;
}