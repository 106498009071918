.stage-5-content{
    >.row{
        >div{
            padding: 0;
            &:first-child{
                padding-right: 15px;
            }
            &:last-child{
                padding-left: 15px;
            }
        }
    }
    .left-side{
        .card{
            box-shadow: none;
            padding: 0;
        }
        .card-content{
            .card-body{
                padding-left: 0;
                >.custom-text-title{
                    margin-bottom: 30px;
                    &.standard{
                        margin-bottom: 15px;
                    }
                    &.image{
                        margin-bottom: 20px;
                    }
                }
                .standard-colors{
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 30px;
                    .color-element{
                        height: 38px;
                        width: 38px;
                        min-width: 38px;
                        border-radius: 100%;
                        border: 1px solid #E0E0E0;
                        display: block;
                        margin-right: 6px;
                        cursor: pointer;
                        margin-bottom: 6px;
                    }
                }
                .picker-section{
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 55px;
                    .form-group{
                        margin-bottom: 0;
                        display: flex;
                        align-items: center;
                        margin-right: 40px;
                        position: relative;
                        label{
                            margin-bottom: 0;
                            margin-right: 12px;
                            text-transform: uppercase;
                            color: #545454;
                            font-size: 15px;
                            font-weight: 500;
                        }
                        .popoverColorPicker{
                            top: 35px;
                        }
                    }
                }
                .media-content{
                    display: flex;
                    &.favicon {
                        margin-top: 20px;
                        & > div {
                            .media-side{
                                & > div {
                                    > img{
                                        min-width: 16px;
                                    }
                                }
                            }
                        }                       
                    }
                    > div{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        margin-right: 10%;
                        &:last-child{
                            margin-right: 0;
                        }
                        label{
                            margin-bottom: 0;
                            span{
                                text-transform: uppercase;
                                color: #545454;
                                font-size: 15px;
                                font-weight: 500;
                                white-space: nowrap;
                            }
                        }
                        .media-side{
                            margin-top: 17px;
                            display: flex;
                            align-items: flex-start;
                            flex-wrap: wrap;
                            >div{
                                position: relative;
                                margin-right: 15px;
                                >img{
                                    min-width: 120px;
                                }
                                .media-right{
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    background: rgba(0, 0, 0, 0.437387);
                                    padding: 2px 5px;
                                    i{
                                        font-size: 16px;
                                        color:  #FFFFFF;
                                    }
                                }
                            }
                            .btn-create{
                                border-color: #00ADFF;
                                padding: 4px 12px;
                                margin-top: 10px;
                                span{
                                    color: #00ADFF;
                                }
                                &:hover{
                                    background-color: #00ADFF;
                                    span{
                                        color: white;
                                    }
                                }
                            }
                        }
                    }
                }
                .expert-side{
                    margin-top: 63px;
                    >div{
                        margin-bottom: 20px;
                        >label{
                            margin-left: 16px;
                            text-transform: uppercase;
                            color: #545454;
                            font-size: 15px;
                            font-weight: 500;
                        }
                    }
                    >.custom-text-title{
                        margin-bottom: 30px;
                    }
                    .card-expert{
                        margin-top: 20px;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.0580276);
                        padding: 25px 30px;
                        >.custom-text-title{
                            margin-bottom: 10px;
                        }
                        .form-group{
                            >label{
                                text-transform: uppercase;
                                color: #545454;
                                font-size: 15px;
                                font-weight: 500;
                            }
                            input, textarea{
                                background-color: #F8F8F8;
                                border-radius: 0;
                                border: none;
                                font-size: 16px;
                                color: #000000;
                                margin-bottom: 24px !important;
                            }
                        }
                        .media-side{
                            margin-top: 10px;
                            display: flex;
                            align-items: flex-start;
                            >div{
                                position: relative;
                                margin-right: 15px;
                                .media-right{
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    background: rgba(0, 0, 0, 0.437387);
                                    padding: 5px;
                                    i{
                                        font-size: 20px;
                                        color:  #FFFFFF;
                                    }
                                }
                            }
                            .btn-create{
                                border-color: #00ADFF;
                                padding: 4px 12px;
                                margin-top: 10px;
                                span{
                                    text-transform: uppercase;
                                    color: #00ADFF;
                                }
                                &:hover{
                                    background-color: #00ADFF;
                                    span{
                                        color: white;
                                    }
                                }
                            }
                        }
                    }
                }
                .discount-side{
                    >div{
                        >label{
                            margin-left: 16px;
                            text-transform: uppercase;
                            color: #545454;
                            font-size: 15px;
                            font-weight: 500;
                        }
                    }
                    .discount-container{
                        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.0580276);
                        padding: 20px 30px;
                        margin-top: 15px;
                        .discount-top{
                            width: 100%;
                            max-width: unset;
                            display: flex;
                            align-items: flex-start;
                            fieldset{
                                input{
                                    background-color: #F8F8F8;
                                    border-radius: 0;
                                    border: none;
                                    font-size: 16px;
                                    color: #000000;
                                    height: 41px;
                                    margin-right: 6px;
                                }
                            }
                            >div:first-child{
                                margin-right: 40px;
                                input{
                                    margin: 0;
                                    max-width: 204px;
                                    width: 100%;
                                    background-color: #F8F8F8;
                                    border-radius: 0;
                                    border: none;
                                    font-size: 16px;
                                    color: #000000;
                                    height: 41px;
                                    padding: 0 10px;
                                }
                            }
                        }
                        .discount-bottom{
                            >label{
                                text-transform: uppercase;
                                color: #545454;
                                font-size: 15px;
                                font-weight: 500;
                            }
                            fieldset{
                                input{
                                    background-color: #F8F8F8;
                                    border-radius: 0;
                                    border: none;
                                    font-size: 16px;
                                    color: #000000;
                                    width: 95px;
                                    max-width: 95px;
                                    min-width: 95px;
                                    margin-right: 15px;
                                }
                                span{
                                    width: 180px;
                                }
                            }
                        }
                    }
                }
                >.form-group{
                    margin-top: 40px;
                    >label{
                        text-transform: uppercase;
                        color: #545454;
                        font-size: 15px;
                        font-weight: 500;
                        margin-bottom: 15px;
                    }
                    select{
                        width: 50%;
                        max-width: 220px;
                    }
                    select, textarea{
                        background-color: #F8F8F8;
                        border-radius: 0;
                        border: none;
                        font-size: 16px;
                        color: #000000;
                    }
                }
            }
        }
    }
    .right-side{
        margin-top: 24px;
        >.custom-text-title{
            margin-bottom: 20px;
        }
        .design-content{
            padding: 8%;
            box-shadow: 0px 0px 1px rgba(133, 133, 133, 0.5);
            .design-container{
                box-shadow: 0px 0px 19px rgba(133, 133, 133, 0.5);
                background-color: white;
                .design-title{
                    display: flex;
                    padding: 23px;
                    p{
                        height: 5px;
                        width: 70px;
                        display: block;
                        margin-bottom: 0;
                    }
                    span{
                        height: 5px;
                        width: 20px;
                        display: block;
                        margin: 0 5px;
                        margin-bottom: 0;
                    }
                }
                .design-body{
                    padding: 12px 23px 80px 23px;
                    .line{
                        display: flex;
                        align-items: center;
                        margin-bottom: 12px;
                        div{
                            height: 12px;
                            width: 12px;
                            margin-right: 8px;
                        }
                        span{
                            display: block;
                            height: 5px;
                            width: 38px;
                        }
                        &:nth-child(3){
                            span{
                                width: 46px;
                            }
                        }
                    }
                }
                .design-footer{
                    padding: 0 16px 12px;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    .footer-text{
                        display: flex;
                        margin-bottom: 2px;
                        p,span{
                            height: 3px;
                            margin: 0;
                        }
                        p{
                            width: 70px;
                        }
                        span{
                            width: 100px;
                        }
                    }
                    .footer-buttons{
                        display: flex;
                        div{
                            width: 50px;
                            height: 22px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 5px;
                            span{
                                display: block;
                                width: 30px;
                                height: 3px;
                                background-color: white;
                            }
                        }
                    }
                }
            }
        }
    }
}