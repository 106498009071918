.step-2-content{
    padding-top: 10px;
    .step-2-header-options{
        >div{
            width: 100%;
            >.form-group{
                display: flex;
                margin-bottom: 0;
                padding-bottom: 25px;
                margin-bottom: 30px;
                border-bottom: 1px solid #E9E9E9;
                >div{
                    margin-right: 100px;
                    label{
                        span{
                            text-transform: uppercase;
                            color: #545454;
                            font-size: 15px;
                            font-weight: 500;
                        }
                    }
                }
                .label-text-before-contacts{
                    margin-top: 0;
                    >label{
                        font-weight: 500;
                        color: #545454;
                        font-size: 15px;
                        text-transform: uppercase;
                        margin-bottom: 15px;
                    }
                    input{
                        background-color: #F8F8F8;
                        border-radius: 0;
                        border: none;
                        font-size: 16px;
                        color: #000000;
                    }
                }
            }
            >.header-options-results{
                .form-group{
                    >label{
                        padding-bottom: 35px;
                        span{
                            color: #A7A7A7;
                            font-weight: 500;
                            font-size: 17px;
                            text-transform: uppercase;
                        }
                    }
                    >.form-group{
                        .form-check-inline{
                            width: 32%;
                            >label{
                                .custom-control{
                                    >label{
                                        text-transform: uppercase;
                                        font-weight: 500;
                                        font-size: 15px;
                                        color: #545454;
                                        margin-left: 12px;
                                        margin-top: 3px;
                                    }
                                }
                                .result-info{
                                    margin-top: 15px;
                                    max-width: 290px;
                                    p{
                                        font-weight: normal;
                                        font-size: 15px;
                                        line-height: 18px;
                                        color: #909090;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    >.row{
        &.drop{
            flex-direction: column;
            .right-side{
                margin-top: 30px;
            }
        }
        >.fixed-container{
            .fixed-result{
                padding-top: 30px !important;
            }
        }
        >.computed{
            .result-formula{
                border: 2px solid #190085;
            }
        }
        >div{
            position: relative;
            padding: 0;
            &:first-child{
                padding-right: 15px;
                padding-left: 0 !important;
            }
            &:last-child{
                padding-left: 15px;
            }
            .fixed-result{
                padding-top: 210px;
                >button{
                    font-weight: 500;
                    font-size: 14px;
                    text-transform: uppercase;
                    padding: 8px 18px;
                    top: 0px;
                    position: absolute;
                    &.showPanel{
                        color: #190085;
                        border-color: #190085;
                        &:hover{
                            background-color: #190085;
                            color: white;
                        }
                    }
                }
                >.card{
                    background-color: #fff !important;
                    box-shadow: none;
                    border: 1px solid #E0E0E0;
                    border-radius: 0;
                    &.selected{
                        border: 2px solid #190085 !important;
                    }
                    .card-header{
                        .card-title{
                            .fix-result-title{
                                max-width: 260px;
                                display: block;
                                margin-bottom: 15px;
                                background: #583E9F;
                                color: white;
                                text-transform: uppercase;
                                padding: 5px 7px;
                                font-weight: bold;
                                font-size: 14px;
                            }
                            >span{
                                font-weight: normal;
                                font-size: 17px;
                                color: #000000;
                            }
                        }
                        .heading-elements{
                            .list-inline{
                                .fa-times{
                                    font-size: 20px;
                                    color: #D7D7D7;;
                                    &:hover{
                                        color: black;
                                    }
                                }
                            }
                        }
                    }
                    .card-content{
                        .isShowEnd{
                            border-radius: 0;
                        }
                        .card-body{
                            font-size: 15px;
                            color: #878787;
                            >.card-img{
                                img{
                                    height: 110px;
                                    width: auto;
                                    border-radius: 0;;
                                }
                            }
                        }
                    }
                }
            }
            .result-formula{
                cursor: pointer;
                padding: 24px 28px;
                position: absolute;
                top: 70px;
                background: #FFFFFF;
                border: 1px solid #E0E0E0;
                width: calc(100% - 30px);
                .textarea-field{
                    .empty-data{
                        color: #878787;
                        font-size: 16px;
                        i{
                            color: #FF8E00;
                            margin-left: 5px;
                        }
                    }
                }
                >label{
                    background: #583E9F;
                    color: #fff;
                    text-transform: uppercase;
                    padding: 5px 10px;
                    margin-bottom: 10px;
                    i{
                        margin-right: 10px;
                    }
                }
                #results_formula{
                    resize: inherit;
                    border: none;
                    padding: 0;
                    color: #878787;
                    font-size: 16px;
                    font-weight: normal;
                }
            }
        }
        >.right-side{
            margin-top: 70px;
            .edit-card{
                padding: 34px 36px;
                .modal-body{
                    .form-group{
                        .range-label{
                            margin-top: 30px;
                            >div{
                                label{
                                    font-weight: 500;
                                    color: #545454;
                                    font-size: 15px;
                                    text-transform: uppercase;
                                }
                                input{
                                    background-color: #F8F8F8;
                                    border-radius: 0;
                                    border: none;
                                    font-size: 16px;
                                    color: #000000;
                                }
                            }
                        }
                        &.upload-img{
                            margin-top: 20px;
                            >p{
                                font-weight: 500;
                                color: #545454;
                                font-size: 15px;
                                text-transform: uppercase;
                                margin-bottom: 15px;
                                margin-top: 20px;
                            }
                            .img-content{
                                display: flex;
                                align-items: flex-start;
                                margin-bottom: 30px;
                                .btn-create{
                                    color: #00ADFF;
                                    border-color: #00ADFF;
                                    font-weight: 500;
                                    font-size: 14px;
                                    text-transform: uppercase;
                                    padding: 8px 16px;
                                    margin-top: 10px;
                                    &:hover{
                                        background-color: #00ADFF;
                                        color: white;
                                    }
                                }
                                .media{
                                    margin-right: 20px;
                                    position: relative;
                                    .media-left{
                                        img{
                                            border-radius: 0;
                                        }
                                    }
                                    .media-right{
                                        position: absolute;
                                        right: 0;
                                        top: 0;
                                        background: rgba(0, 0, 0, 0.437387);
                                        padding: 5px;
                                        i{
                                            font-size: 20px;
                                            color:  #FFFFFF;
                                        }
                                    }
                                }
                            }
                        }
                        >label{
                            font-weight: 500;
                            color: #545454;
                            font-size: 15px;
                            text-transform: uppercase;
                            margin-bottom: 15px;
                        }
                        >input, textarea, select{
                            background-color: #F8F8F8;
                            border-radius: 0;
                            border: none;
                            font-size: 16px;
                            color: #000000;
                        }
                        >fieldset{
                            .form-check{
                                margin-right: 40px;
                                .custom-control{
                                    .custom-control-label{
                                        margin-top: 4px;
                                        font-weight: 500;
                                        color: #545454;
                                        font-size: 15px;
                                        text-transform: uppercase;
                                        margin-bottom: 15px;
                                        margin-left: 13px;
                                    }
                                }
                            }
                        }
                        &.switch-label{
                            >fieldset{

                                >span{
                                    text-transform: uppercase;
                                    font-weight: 500;
                                    font-size: 15px;
                                    color: #545454;
                                }
                                .switch-content{
                                    margin-right: 15px;
                                }
                            }
                        }
                    }
                }
                .modal-footer{
                    background-color: #fff;
                    border: none;
                    margin-top: 50px;
                    justify-content: flex-start;
                    padding-left: 0;
                    .btn-create{
                        text-transform: uppercase;
                        font-size: 14px;
                        font-weight: 500;
                        margin-right: 20px;
                        &:hover{
                            color: white !important;
                            background-color: #A7A7A7;
                            border: #A7A7A7;
                        }
                        &.green{
                            border-color: #00B371 !important;
                            color: #00B371;
                            &:hover{
                                background-color: #00B371;
                            }
                        }
                    }
                }
            }
            #colourful-treeview{
                padding: 34px 36px;
                >p{
                    font-weight: 500;
                    font-size: 15px;
                    color: #545454;
                    text-transform: uppercase;
                    background-color: #fff;
                    margin-bottom: 20px;
                }
                .list-group{
                    .list-group-content{
                        margin-bottom: 40px;
                        .node-colourful-treeview{
                            background-color: #fff;
                            border-color: #fff;
                            color: black;
                            border-radius: 0;
                            padding: 0;
                            margin-bottom: 10px;
                            font-size: 16px;
                            &:hover{
                                .fa-plus{
                                    color: #005b85;
                                }
                            }
                            &.list-title{
                                font-weight: 500;
                                font-size: 16px;
                                color: #000000;
                                margin-bottom: 17px;
                            }
                            .fa-plus{
                                color: #00ADFF;
                                margin-right: 10px;
                                font-size: 16px;
                            }
                            .badge-default{
                                background-color: #00ADFF;
                                border-radius: 0;
                                margin-left: 10px;
                            }
                            .badge-info{
                                border-radius: 0;
                                background-color: #E0186C;
                            }
                        }
                    }
                }
            }
        }
    }
}