.packages-page{
    margin-top: -40px;
    padding-bottom: 40px;
    .packages-cards{
        .card-body{
            padding: 30px;
            .card-title{
                display: flex;
                justify-content: space-between;
                .card-type{
                    display: flex;
                    span{
                        font-weight: bold;
                        font-size: 16px;
                        color: #000000;
                        margin-right: 5px;
                    }
                    p{
                        font-weight: 400;
                        color: black;
                    }
                }
                .card-date{
                    text-transform: uppercase;
                    font-size: 16px;
                    color: #717171;
                }
            }
        }
        .media{
            .media-body{
                h3{
                    text-transform: uppercase;
                    font-size: 18px;
                    margin-bottom: 10px;
                    &.info{
                        color: #00915C !important; 
                    }
                    &.bd-danger{
                        color: #FF3737 !important;
                    }
                    &.infinity{
                        color: #E41977 !important;
                    }
                }
            }
        }
        .progress{
            border-radius: 0;
            margin-top: 0 !important;
            .info{
                background-color: #00C87F !important;
            }
            .bd-danger{
                background-color: #FF3737 !important;
            }
            .infinity{
                background-color: #E0186C !important;
            }
        }
    }
    .packages-info{
        max-width: 600px;
        display: flex;
        flex-direction: column;
        margin: auto;
        .responsive-packages{
            width: 100%;
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
    }
    .packages-types{
        display: flex;
        max-width: 644px;
        margin: auto;
        justify-content: space-between;
        .package-type{
            width: 50%;
            display: flex;
            flex-direction: column;
            &:first-child{
                .package-body{
                    border-right: 1px solid #D7D7D7;
                    padding-right: 20px;
                }
            }
            .package-title{
                text-align: center;
                p{
                    font-weight: 500;
                    font-size: 18px;
                    color: black;
                }
            }
            .package-body{
                width: 100%;
                margin: auto;
                margin-top: 35px;
                .package-item{
                    display: flex;
                    margin: auto;
                    margin-bottom: 5px;
                    width: 250px;
                    padding-left: 40px;
                    i{
                        margin-right: 25px;
                        margin-top: 5px;
                        font-size: 20px;
                        &.fa-check{
                            color: #00C87F;
                            margin-right: 22px;
                        }
                        &.fa-times{
                            color: #9B9B9B;
                            font-size: 22px;
                        }
                    }
                    p{
                        font-size: 16px;
                    }
                }
            }
            .btn-create{
                width: 130px;
                padding: 7px;
                display: block;
                margin: auto;
                margin-top: 30px;
                text-transform: uppercase;
            }
        }
    }
    .package-rate{
        display: flex;
        justify-content: center;
        >.package-type{
            text-align: center;
            p{
                margin: 10px 0 20px;
            }
            >h3{
                margin-bottom: 50px;
                color: black;
            }
            .btn-create{
                padding: 8px 19px;
                margin-top: 10px;
            }
            .list{
                >div{
                    background: #FFFFFF;
                    border: 3px solid #E2E2E2;
                    box-sizing: border-box;
                    border-radius: 41px;
                    margin-bottom: 15px;
                    padding: 5px 0;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    cursor: pointer;
                    &:hover{
                        border-color: #00CF8A;
                    }
                    h4{
                        margin: 0 5px;
                    }
                    span{
                        font-size: 12px;
                    }
                }
                &.unlimit{
                    >div{
                        padding: 20px 40px;
                        width: 700px;
                        justify-content: space-between;
                        align-items: center;
                        text-align: left;
                        h4{
                            margin: 0;
                            font-weight: 500;
                            font-size: 18px;
                            color: black;
                        }
                        p{
                            margin: 0;
                            color: #636363;
                            font-size: 18px;
                        }
                        span{
                            &.active{
                                color: #00ADFF;
                            }
                        }
                    }
                }
            }
        }
    }
}