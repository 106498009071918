.quiz-fooer{
    height: 70px;
    border-top: 1px solid #DCDCDC;
    margin: 0 5%;
    display: flex;
    align-items: center;
    max-width: 1280px;
    margin: auto;
    a{
        margin-right: 32px;
        color: #555555;
    }
}