.containerTooltip > div {
     display: block !important;
}

.quizzWarning {
    width: 100%;
    background-color: #ffb794;
    color: #b95b3b;
    margin-bottom: 1.875rem;
    border: 1px solid #ff8a56;
    border-radius: 0.35rem;
    padding: 15px;
}
.quizzWarning-title {
    display: flex;
    justify-content: space-between;
}
.quizzWarning-title h2 {
    display: inline-flex;
    color: #b95b3b;
}
.quizzWarning-title i {
    display: inline-flex;
    color: #b95b3b;
    cursor: pointer;
    font-size: 1.5rem;
}

