/*================================================================================
	Item Name: Modern Admin - Clean Bootstrap 4 Dashboard HTML Template
	Version: 1.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
.prune {
    display: inline-block;
    white-space: nowrap; /* Отменяем перенос текста */
    overflow: hidden; /* Обрезаем содержимое */
    margin-bottom: -5px;
    width: 25rem;
    /*padding: 5px; !* Поля *!*/
    /*background: #fc0; !* Цвет фона *!*/
    position: relative; /* Относительное позиционирование */
}
.prune::after {
    content: ''; /* Выводим элемент */
    position: absolute; /* Абсолютное позиционирование */
    right: -5px; top: 0; /* Положение элемента */
    width: 40px; /* Ширина градиента*/
    height: 100%; /* Высота родителя */
    /* Градиент */
    background: linear-gradient(to right, rgb(255 255 255 / 20%), #ffffff 100%);
}

.pruneDropDownAnswer {
    display: inline-block;
    white-space: nowrap; /* Отменяем перенос текста */
    overflow: hidden; /* Обрезаем содержимое */
    margin-bottom: -5px;
    width: 17rem;
    /*padding: 5px; !* Поля *!*/
    /*background: #fc0; !* Цвет фона *!*/
    position: relative; /* Относительное позиционирование */
}
.pruneDropDownAnswer::after {
    content: ''; /* Выводим элемент */
    position: absolute; /* Абсолютное позиционирование */
    right: 0; top: 0; /* Положение элемента */
    width: 40px; /* Ширина градиента*/
    height: 100%; /* Высота родителя */
    /* Градиент */
    /*background: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff 100%);*/
}

.hidePanel {
    opacity: 0;
}

.showPanel {
    opacity: 1;
}

.node-colourful-treeview {
    color: #FFF;
    background-color: #3BAFDA;
    border-color: #1cade0;
}

.node-colourful-treeview:hover {
    background-color: #1cade0;
}

.template-heading-elements {
    text-align: end;
    padding-right: 15px;
    padding-top: 10px;
    font-size: 1.3rem;
    z-index: 10;
}

.template-actions-button {
    margin-right: 0.5rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.drop-down-item-menu {
    width: 100%;
    padding: 10px 9px !important;
}

.position-loader {
    position: absolute;
    top: 50%;
    left: 50%;
}

.loader-modal {
    position: relative;
    top: auto;
    left: auto;
    margin: 0 auto;
}

.move-contacts {
    cursor: move;
    padding-right: 0.5rem;
    opacity: 0.2;
}

.move-contacts:hover {
    opacity: 1;
}

.fix-nav {
    white-space: inherit !important;
}

.margin-right-quizzes {
    margin-right: -5px !important;
}

.margin-right-packages {
    margin-right: -5px !important;
    margin-left: 0px !important;
}

.margin-right-confirm {
    margin-right: -15px !important;
    margin-left: 0px !important;
    float: right;
}
/*.pagination ul {*/
    /*display: inline-block;*/
    /*padding-left: 15px;*/
    /*padding-right: 15px;*/
/*}*/

/*.pagination li {*/
    /*display: inline-block;*/
    /*padding-left: 15px;*/
    /*padding-right: 15px;*/
/*}*/

/*.pagination .break a {*/
    /*cursor: default;*/
/*}*/

.pagination-active > .page-link{
    background-color: #00BCD4;
    color: white !important;
}

.pagination .page-link {
    color: #6B6F82;
}

#react-paginate .pagination-active {
    cursor: default;
}

.fix-icons {
    font-family: 'Font Awesome 5 Brands' !important;
}

.action-lead {
    margin-right: 5px;
    color: white !important;
}

.top-buttons {
    margin-right: 5px;
}

.fix-menu {
    left: auto !important;
}

.subdivtext {
    border-radius: 25px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
}

.round-image {
    border-radius: 10px;
}
/*expandIcon: "ft-stop-circle",*/
/*collapseIcon: "ft-check-square",*/
/*nodeIcon: "icon-head",*/
/*color: "#FFF",*/
/*backColor: "#3BAFDA",*/
/*onhoverColor: "#1cade0",*/
/*borderColor: "#1cade0",*/
/*showBorder: false,*/
/*showTags: true,*/
/*highlightSelected: true,*/
/*selectedColor: "#FFF",*/
/*selectedBackColor: "#1cade0",*/
/*data: defaultData*/
