.quiz-step-1{
    margin: 0;
    display: flex;
    justify-content: space-between;
    >div:first-child{
        padding-left: 0;
        .card{
            box-shadow: none;
            margin-bottom: 0;
            .card-body{
                padding-bottom: 0;
                .form-group{
                    margin-bottom: 48px;
                    >label{
                        color: #545454;
                        font-weight: 500;
                        font-size: 15px;
                        text-transform: uppercase;
                        margin-left: 3px;
                    }
                    input, textarea{
                        background: #F8F8F8;
                        border-color: #F8F8F8;
                        border-radius: 0;
                        color: black;
                    }
                }
            }
        }
    }
    >div:last-child{
        padding-right: 0;
        .without{
            min-height: 300px;
        }
        .bg-blue-grey{
            .card-img-overlay{
                background: rgba(0, 0, 0, 0.537449);
            }
        }
        .card-content{
            min-height: 380px;
            background-size: cover;
            background-position: center;
            .card-img-overlay{
                background: rgba(0, 0, 0, 0.537449);
                opacity: 0.87;
                mix-blend-mode: normal;
            }
            .card-content-info{
                position: relative;
                width: 90%;
                top: 0%;
                left: 50%;
                transform: translate(-50%, 0%);
                z-index: 999;
                .subdivtext{
                    font-size: 29px;
                    line-height: 34px;
                    font-weight: bold;
                }
            }
            button{
                margin-top: 40px;
                // background: #00ADFF !important;
                border-radius: 21.5px;
                padding: 1rem 28px;
            }
        }
    }
}