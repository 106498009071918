
.cards-body{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.content-wrapper{
    padding-bottom: 80px !important;
    &.edit-quiz{
        .quiz-form-content{
            margin-top: 0;
        }
        .content-header{
            &.row{
                margin: 0;
            }
        }
    }
    .content-header{
        &.row{
            margin: 53px 0 25px;
            .content-header-right{
                padding-right: 0;
                .dropdown{
                    margin-right: 0;
                    margin-left: 20px;
                    button{
                        box-shadow: none;
                    }
                }
                a{
                    .quiz__button--actions{
                        margin-left: 20px;
                        min-height: 38px;
                        height: 38px;
                        &:hover{
                            background-color: black !important;
                            color: white !important;
                        }
                    }
                }

            }
        }
    }
}
.modal{
    .modal-dialog{
        .modal-content{
            padding: 20px 30px;
            input{
                background: #F8F8F8;
                border: none;
                border-radius: 0;
                color: black;
            }
        }
        .modal-footer{
            background-color: white;
            margin-top: 20px;
        }
    }

}
.questions-step{
    // padding-top: 50px;
    .row{
        >div{
            padding: 0;
        }
        .left-side{
            padding-right: 15px;
            .notify{
                border: 2px solid #00ADFF;
                padding: 20px 25px;
                span{
                    text-transform: uppercase;
                    background-color: #00ADFF;
                    color: white;
                    padding: 4px 10px;
                }
                p{
                    margin-top: 20px;
                    margin-bottom: 0;
                }
            }
            #controlPanel{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right: 5px;
                margin-bottom: 28px;
                .get-show-cards{
                    color: #AAAAAA;
                    &:hover{
                        i{
                            color: black !important;
                        }
                    }
                    cursor: pointer;
                    &.show{
                        i{
                            transform: rotate(0deg);
                            transition-duration: 0.5s;
                        }
                    }
                    i{
                        transition-duration: 0.5s;
                        margin-right: 10px;
                        font-size: 18px;
                        color: #AAAAAA;
                        transform: rotate(180deg);
                    }
                    span{
                        color: #AAAAAA;
                        font-weight: normal;
                        font-size: 14px;
                    }
                }
                .btn-create{
                    color: #00ADFF;
                    border-color: #00ADFF;
                    padding: 8px 16px;
                    &:hover{
                        color: white;
                        background-color: #00ADFF;
                        border: 3px solid #00ADFF;
                    }
                    i{
                        margin-right: 10px;
                    }
                }
            }
        }
        .right-side{
            margin-top: 66px;
            padding-left: 15px;
            .modal-content{
                border: none;
            }
            >div{
                padding: 36px 33px;
                position: sticky;
                top: 120px;
                overflow-y: auto;
                height: calc(100vh - 260px);
                padding-right: 20px;
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.0580276);
            }
            .modal-body{
                .drop-down-side{
                    .dropdown{
                        .btn-secondary{
                            background: #F8F8F8;
                            border: none;
                            color: black;
                            border-radius: 0;
                            padding-right: 40px;
                            padding-left: 20px;
                        }
                        .dropdown-item{
                            cursor: pointer;
                        }
                    }
                    .select{
                        width: 230px;
                        display: inline-block;
                        margin-left: 5%;
                    }
                }
                .min-max-side{
                    >div{
                        display: flex;
                        >fieldset{
                            width: 40%;
                            margin-right: 10%;
                        }
                    }
                }
                .form-group{
                    label{
                        color: #545454;
                        font-size: 15px;
                        font-weight: 500;
                        text-transform: uppercase;
                    }
                }
                .switchery-default{
                    margin-right: 10px;
                }
                fieldset{
                    span, label{
                        color: #545454;
                        font-size: 15px;
                        font-weight: 500;
                        text-transform: uppercase;
                    }
                    input, textarea, select, .custom-select{
                        background-color: #F8F8F8;
                        border: none;
                        border-radius: 0;
                        color: black;
                    }
                }
                .image-media{
                    padding: 20px 0;
                    .image-media-title{
                        margin-bottom: 15px;
                        span{
                            font-size: 15px;
                            font-weight: 500;
                            text-transform: uppercase;
                            color: #545454;
                            margin-right: 10px;
                        }
                        i{
                            color: #BDBDBD;
                        }
                    }
                    .image-media-body{
                        display: flex;
                        align-items: flex-start;
                        img{
                            border-radius: 0;
                        }
                        .img-upload{
                            color: #00ADFF;
                            border-color: #00ADFF;
                            padding: 6px 14px;
                            font-weight: 500;
                            font-size: 14px;
                            text-transform: uppercase;
                            &:hover{
                                background-color: #00ADFF;
                                color: white;
                            }
                        }
                        .media{
                            position: relative;
                            margin-right: 20px;
                            .media-left{
                                .btn-icon{
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    background: rgba(0, 0, 0, 0.437387);
                                    color: white;
                                    padding: 0 4px;
                                    i{
                                        font-size: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .modal-footer{
                background-color: white;
                border: none;
                position: fixed;
                bottom: 80px;
                width: calc(44% - 40px);
                left: calc(50% + 15px);
                display: flex;
                justify-content: flex-start;
                box-shadow: -30px 0px 20px rgba(0, 0, 0, 0.0580276);
                max-width: 590px;
                .btn-create {
                    margin-left: 15px;
                    &.reset{
                        color: #E0186C;
                        border-color: #E0186C;
                        &:hover{
                            color: white;
                            background-color: #E0186C;
                        }
                    }
                    &.submit{
                        color: #00C87F;
                        border-color: #00C87F;
                        &:hover{
                            background-color: #00C87F;
                            color: white;
                        }
                    }
                }
            }
        }
    }
}
.questions-quest, #question-side{
    .card-quest{
        background-color: white !important;
        border: 1px solid #E0E0E0;
        border-radius: 0;
        &.active{
            border: 2px solid #00ADFF !important;
        }
        >.card-header{
            display: flex;
            align-items: flex-start;
            .badge{
                i{
                    margin-right: 7px;
                }   
                span{
                    display: contents;
                }
            }
            .card-header-title{
                width: calc(100% - 200px);
                display: inline-block;
                margin-left: 15px;
                font-weight: 500;
                font-size: 17px;
                color: black;
            }
            .badge-info{
                background: #00ADFF;
                border-radius: 0;
                font-weight: bold;
                font-size: 14px;
                padding: 6px 12px;
                text-transform: uppercase;
                height: 26px;
            }
            .badge-danger{
                border-radius: 0;
                font-weight: 500;
                font-size: 14px;
                padding: 6px 12px;
                background: #EFEFEF;
                color: black;
                height: 26px;
            }
            .heading-elements{
                display: flex;
                align-items: center;
                li{
                    margin-top: 5px;
                    a{
                        padding: 0;
                    }
                    i{
                        color: #D2D2D2;
                        font-size: 20px;
                        margin: 0 10px;
                        &:hover{
                            color: black !important;
                        }
                    }
                }
            }
        }
        >.card-content{
            .cards-body{
                >h4{
                    // border: 1px solid red;
                    margin: 0 0 10px !important;
                    font-weight: 500;
                    font-size: 17px;
                    color: black;
                    width: 100%;
                }
                >img{
                    border-radius: 0;
                    margin-bottom: 20px;
                }
                .row{
                    .col-md-12{
                        padding: 0;
                    }
                    .btn-create{
                        width: 94px;
                        padding: 5px 0;
                        background-color: white !important;
                        text-transform: uppercase;
                        span{
                            font-weight: 600;
                        }
                        i{
                            margin-right: 5px;
                        }
                        &.btn-blue-grey{
                            color: #E0186C !important;
                            border-color: #E0186C !important;
                            &:hover{
                                background-color: #E0186C !important;
                                color: white !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.quest-answer, #answer-side{
    background: #FFFFFF !important;
    border: none;
    box-sizing: border-box;
    border-radius: 0;
    margin-bottom: 10px;
    &.option-type{
        border: 1px solid #E0E0E0 !important;
        padding: 10px 15px !important;
        &:hover{
            border-color: #afafaf !important;
        }
        &.active{
            border: 2px solid #E0186C !important;
        }
    }
    .answer-title-side{
        width: 100%;
    }
    >.card-header{
        padding: 0 !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4{
            font-weight: normal;
            font-size: 16px;
            color: black;
        }
        .answer-title-side{
            .badge {
                border-radius: 0;
                margin-right: 5px;
            }
        }
        .heading-elements{
            position: unset;
            ul{
                display: flex;
                li{
                    a{
                        padding: 0 !important;
                        margin: 0 !important;
                        i{
                            margin: 0 5px !important;
                            font-size: 16px !important;
                            color: #D2D2D2;
                            &:hover{
                                color: black !important;
                            }
                        }
                    }
                }
            }
        }
    }
    >.card-content{
        .card-body{
            padding: 0 !important;
            img{
                border-radius: 0;
            }
            .badge{
                border-radius: 0;
                margin-right: 5px;
                height: 20px;
            }
            >span{
                .badge{
                    border-radius: 0;
                    margin-right: 5px;
                }
            }
            .body-footer{
                margin-top: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                >button{
                    margin-bottom: 0 !important;
                }
                >div{
                    width: 50%;
                    display: flex;
                    margin-right: 0 !important;
                    margin-bottom: 0 !important;
                    &.dropup {
                        .dropdown-menu {
                            padding: 15px;
                        }
                    }
                    button{
                        background-color: white !important;
                        color: black !important;
                        border: none !important;
                        padding: 0;
                        width: 95%;
                        display: inline-flex;
                        position: relative;
                        span{
                            white-space: nowrap; 
                            text-overflow: clip; 
                            overflow: hidden;
                            font-size: 14px;
                            color: #727272;
                            b{
                                color: black;
                            }
                        }
                        &::after{
                            top: 6px;
                            right: 0px;
                            transform: rotate(180deg);
                            color: #727272;
                            font-weight: 900;
                            font-size: 10px;
                            width: 20px;
                            height: 10px;
                        }
                    }
                }
            }
        }
    }
}

.custom-select{
    z-index: 9999999999999999999;
    height: unset !important;
    padding: 0;
    position: relative;
    .select-header{
        cursor: pointer;
        padding: 0;
        padding: 15px 40px 15px 17px;
        position: relative;
        .fa-angle-down{
            position: absolute;
            right: 10px;
            top: 40%;
            transform: translateX(-50%);
        }
        >div{
            display: flex;
            align-items: flex-start;
            p{
                color: #545454 !important;
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 3px;
                text-transform: uppercase !important;
            }
            span{
                font-weight: normal;
                font-size: 15px;
                color: #909090 !important;
                text-transform: unset !important;
            }
            i{
                color: #00C87F;
                font-size: 20px;
                margin-right: 20px;
                margin-top: 5px;
            }
        }
    }
    .select-body{
        background: #FFFFFF;
        border: 1px solid #E4E4E4;
        box-sizing: border-box;
        z-index: 9999999999999999999;
        width: 100%;
        top: 60px;
        left: 0;
        position: absolute;
        .select-item{
            display: flex;
            align-items: flex-start;
            cursor: pointer;
            padding: 15px 27px;
            &:first-child{
                padding-top: 27px;
            }
            &:last-child{
                padding-bottom: 27px;
            }
            &:hover{
                background-color: #cfcfcf;
            }
            i{
                color: #00C87F;
                font-size: 20px;
                margin-right: 20px;
                margin-top: 5px;
            }
            >div{
                p{
                    color: #545454;
                    font-size: 15px;
                    font-weight: 500;
                    margin-bottom: 3px;
                    text-transform: uppercase !important;
                }
                span{
                    font-weight: normal !important;
                    font-size: 15px !important;
                    color: #909090 !important;
                    text-transform: unset !important;
                }
            }
        }
    }
}