.integration-page{
    margin-top: -50px;
    >h1{
        font-weight: 300;
        font-size: 35px;
        color: #9B9B9B;
    }
    h2{
        font-weight: 300;
        font-size: 24px;
        color: #9B9B9B;
        margin: 20px 0 30px;
    }
    >div{
        margin-top: 40px;
        display: flex;
        .left-side{
            width: 320px;
            min-width: 320px;
            margin-right: 10%;
            .integration-elem{
                padding: 22px 27px;
                border: 1px solid #E2E2E2;
                color: black;
                font-weight: bold;
                font-size: 22px;
                margin-bottom: 10px;
                cursor: pointer;
                &:hover{
                    background-color: #E2E2E2;
                }
                &.active{
                    color: white;
                    background-color: black;
                }
                >p{
                    margin: 0;
                }
                >span{
                    // font-weight: bold;
                    font-size: 13px;
                    text-transform: uppercase;
                    color: white;
                    background: #FF7D00;
                    padding: 3px 6px;
                }
            }
        }
        section{
            >h1{
                font-weight: 300;
                font-size: 34px;
                color: black;
                margin-bottom: 30px;
            }
            >p{
                font-size: 20px;
                color: black;

            }
            >div{
                margin-top: 20px;
                &.links{
                    >a{
                        font-size: 18px;
                        margin-right: 20px;
                    }
                }
                >span{
                    font-size: 18px;
                    color: black;
                    font-weight: 600;
                }
                ol{
                    padding-top: 10px;
                    padding-left: 25px;
                    li{
                        font-size: 18px;
                        color: black;
                    }
                }
            }
        }
    }
}