.stage-4-content{
    .stage-4-header-bar{
        margin-bottom: 50px;
    }
    .left-side{
        .card{
            box-shadow: none;
            .card-content{
                .custom-text-title{
                    &.type-2{
                        margin-bottom: 30px;
                    }
                }
                .card-body{
                   padding: 0;
                   >div{
                       .form-check-inline{
                           >label{
                               .custom-radio{
                                   label{
                                       margin-top: 4px;
                                       margin-left: 12px;
                                       color: #545454;
                                    }
                                }
                                >span{
                                    font-size: 15px;
                                    color: #909090;
                                }
                            }
                        }
                        .radio-control{
                            display: flex;
                            margin-bottom: 20px;
                            .form-check-inline{
                                max-width: 240px;
                                &:first-child{
                                    margin-right: 20%;
                                }
                                .custom-control{
                                    margin-bottom: 15px;
                                }
                            }
                        }
                        .checkbox-control{
                            .card-body{
                                margin-bottom: 30px;
                                >.custom-text-title{
                                    margin-bottom: 20px;
                                }
                                .col-md-8{
                                    padding: 0 15px;
                                }
                                .skin-square{
                                    fieldset{
                                        margin-bottom: 25px !important;
                                        display: flex;
                                        align-items: center;
                                        .fas, .fa-bars, .move-contacts{
                                            padding-right: 15px !important;
                                            font-size: 16px;
                                            color: #8b8b8b;
                                        }
                                        .check-text{
                                            font-size: 16px;
                                            color: #000000;
                                            .badge, .badge-default, .badge-danger {
                                                border-radius: 0 !important;
                                                margin-left: 10px;
                                            }
                                        }
                                        .icheckbox_square-red{
                                            margin-right: 15px;
                                        }
                                        input{
                                            background-color: #F8F8F8;
                                            border-radius: 0;
                                            border: none;
                                            font-size: 14px;
                                            color: #000000;
                                            padding: 5px 10px;
                                        }
                                    }
                                }
                            }
                            .switch-side{
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .right-side{
        .custom-text-title{
            &.type-2{
                margin-bottom: 30px;
            }
        }
        .form-group{
            >label{
                span{
                    text-transform: uppercase;
                    color: #545454;
                    font-size: 15px;
                    font-weight: 500;
                }
            }
            input, textarea{
                background-color: #F8F8F8;
                border-radius: 0;
                border: none;
                font-size: 16px;
                color: #000000;
                margin-bottom: 25px !important;
            }
        }
    }
}

#drag-side-form{
    .fas, .fa-bars, .move-contacts{
        padding-right: 15px !important;
        font-size: 16px !important;
        color: #8b8b8b;
    }
    .check-text{
        font-size: 16px !important;
    }
    .badge, .badge-default, .badge-danger {
        border-radius: 0 !important;
        margin-left: 10px;
        font-size: 13px;
    }
    input{
        background-color: #F8F8F8;
        border-radius: 0;
        border: none;
        font-size: 14px;
        color: #000000;
        padding: 5px 10px;
    }
}



.custom-text-title{
    text-transform: uppercase;
    color: #545454;
    font-size: 15px;
    font-weight: 500;
    &.type-2{
        color: #A7A7A7;
        font-size: 17px;
    }
}