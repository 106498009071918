.empty-quizes{
    height: 50vh;
    width: 100%;
    position: relative;
    >div{
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        h1{
            margin-bottom: 30px;
            color: #747474;
            font-weight: 300;
            font-size: 28px;
        }
    }
}