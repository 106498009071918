.pagination-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: -68px;
}

.pagination, .search-quiz {
    display: inline-flex;
}

.search-quiz {
    width: 250px;
    align-items: center;
    margin-right: 2.5rem;
    position: relative;
    font-size: 15px;
}

.search-quiz .form-control{
    padding-left: 45px;
    border-radius: 0 !important;
}

.search-quiz-icon {
    font-size: 18px;
    cursor: pointer;
    position: absolute;
    left: 13px;
    font-weight: 600 !important;
    color: #C3C3C3;
}

.show-all-quizzes {
    color: rgb(30, 159, 242) !important;
}

.btn-tertiary {
    color: #555;
    margin: auto;
    display: block;
    border-radius: 1.5rem !important;
}

.input-file {
    width: .1px;
    height: .1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1
}

.input-file+.js-labelFile {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer
}

.input-file+.js-labelFile .icon:before {
    content: "\f093"
}

.input-file+.js-labelFile.has-file .icon:before {
    content: "\f00c";
    color: #5AAC7B
}

.checkbox-container .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .checkbox-container .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 19px;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    box-sizing: border-box;
  }

  .checkmark-disabled {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 19px;
    background: #eaeaea;
    border: 1px solid  #6f6f6f;
    box-sizing: border-box;
  }

  .checkmark-disabled:hover {
    background: #eaeaea !important;
    border: 1px solid  #6f6f6f;
  }

  .disabled .checkmark{
    opacity: 0.5;
  }
  
  /* On mouse-over, add a grey background color */
  .checkbox-container .container:hover input ~ .checkmark {
    background: #f5f5f5;
  }

  .disabled:hover input ~ .checkmark {
    background: #FFFFFF;
    cursor: unset !important;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox-container .container input:checked ~ .checkmark {
    background-color: #FFFFFF;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox-container .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox-container .container .checkmark:after {
    left: 6px;
    top: 2px;
    width: 7px;
    height: 11px;
    border: solid #00C87F;
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }