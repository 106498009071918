.header-navbar{
    .navbar-container{
        .navbar-nav{
            li{
                span{
                    margin-right: 0 !important;
                }
                i{
                    font-size: 20px;
                    color: #B2B2B2;
                    position: relative;
                    margin-right: 0px !important;
                    &.active{
                        color: #000000;
                    }
                    &:hover{
                        color: black;
                    }
                }
                &.dropdown-user{
                    >div{
                        padding: 0 !important;
                        >a{
                            padding: 0 !important;
                        }
                        .dropdown-menu{
                            min-width: 226px;
                            padding: 5px 15px;
                            background: #FFFFFF;
                            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.10796);
                            border: none;
                            border-radius: 0;
                            a{
                                span{
                                    color: black !important;
                                    font-size: 15px;
                                }
                                i{
                                    color: black;
                                    margin-right: 13px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .navbar-header{
        .nav-item {
            .badge-danger{
                padding: 3px 10px;
                border-radius: 100%;
            }
        }
    }
}