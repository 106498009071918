@import '../../assets/fonts/YS_Text/font.css';

body {
  overflow-y: scroll;
}

.contentWrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: flex-start;
  background-color: #FFFFFF;
}

.goBack {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 30px;
  margin-bottom: 30px;
  background: #FFCC00;
  border-radius: 10px;
  font-family: 'YSText'!important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  transition: background 0.25s ease-out;
  cursor: pointer;

  &:hover {
    background: #f3c304;
  }
}

.iframeContainer {
  width: 0%;
  height: 0%;
  visibility: hidden;

  &.visible {
    width: 100%;
    height: 100%;
    visibility: visible;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
}

.commonBanner {
  margin-bottom: 50px;
}

.directWrapper {
  flex: 0 0 50%;
  border-right: 2px solid #E6E6E6;
  min-height: 100vh;

  .logo {
    & > img {
      width: 127px;
      height: 30px;
    }
  }
}

.businessWrapper {
  position: relative;
  flex: 0 0 50%;
  min-height: 100vh;

  .logo {
    & > img {
      width: 162px;
      height: 32px;
    }
  }
}

.logo {
  margin-left: 70px;
  margin-bottom: 25px;
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  & > img {
    width: 100%;
    height: 100%;
  }
}

.text {
  width: 500px;
  margin: 0 auto;
  font-family: 'YSText'!important;
  font-style: normal;
  color: #000000;
  margin-bottom: 40px;

  &.bmb {
    margin-bottom: 64px;
  }

  .title {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 46px;
    line-height: 52px;
    letter-spacing: -0.7px;
  }

  .description {
    font-weight: 400;
    font-size: 17px;
    line-height: 140%;
  }
}

.settings {
  width: 556px;
  height: 525px;
  box-sizing: border-box;
  margin: 0 auto 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
  background-color: #F5F5F5;
  border-radius: 30px;
  position: relative;

  & > div:first-child {
    margin-bottom: 20px;
    font-family: 'YSText'!important;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.001em;
    color: #000000;
  }

  & > ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;

    li {
      position: relative;
      padding-left: 14px;
      margin-bottom: 16px;
      font-family: 'YSText'!important;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 140%;
      color: #000000;
    }

    li::before {
      content: "\2022";
      position: absolute;
      left: 0px;
      color: #8B93A5;
      font-weight: bold;
      font-size: 20px;
    }
  }
}

.btn {
  position: absolute;
  bottom: 30px;
  left: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 40px;
  background: #FFCC00;
  border-radius: 10px;
  font-family: 'YSText'!important;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 140%;
  color: #000000;
  transition: background 0.25s ease-out;
  cursor: pointer;

  &:hover {
    background: #f3c304;
  }
}

.correction {
  width: 500px;
  position: absolute;
  bottom: 50px;
  left: 72px;
  margin-top: 40px;
  font-family: 'YSText'!important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #888888;
}

@media (max-width: 1200px) {
  .logo {
    margin-left: 40px;
  }

  .settings {
    width: 502px;
  }
}

@media (max-width: 1100px) {
  .logo {
    margin-left: 35px;
    margin-bottom: 12px;
  }

  .banner {
    width: 480px;
    margin: 0 auto 25px;
  }

  .text {
    width: 450px;
  }

  .settings {
    width: 453px;
    height: 570px;
    margin: 0 auto 80px;

    & > div:first-child {
      font-size: 26px;
    }
  }

  .correction {
    width: 420px;
    bottom: 8px;
    left: 47px;
  }
}

@media (max-width: 996px) {
  .banner {
    width: 430px;
  }

  .text {
    width: 405px;
    margin-bottom: 55px;

    &.bmb {
      margin-bottom: 30px;
    }

    .title {
      font-size: 46px;
      font-size: 40px;
    }
  }

  .settings {
    width: 405px;
    height: 645px;

    & > div:first-child {
      font-size: 25px;
    }
  }

  .correction {
    width: 375px;
    bottom: 10px;
    left: 60px;
  }
}