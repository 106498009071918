.stage-6-content{
    fieldset{
        >label{
            >span{
                text-transform: uppercase;
                color: #545454;
                font-weight: 500;
                font-size: 15px;
            }
        }
    }
    .col-md-12{
        padding: 0 !important;
        >.card{
            box-shadow: none;
            .card-body{
                padding-left: 0;
                >.form-group{
                    label{
                        span{
                            text-transform: uppercase;
                            color: #A7A7A7;
                            font-weight: 500;
                            font-size: 17px;
                        }
                    }
                    .label-cover{
                        font-size: 14px;
                        color: #909090;
                        text-transform: unset;
                        font-weight: 400;
                        width: 270px;
                        display: block;
                        margin-top: 10px;
                    }
                    >fieldset{
                        margin-top: 15px;
                        >div{
                            display: flex;
                        }
                        .form-check{
                            margin-right: 120px;
                            display: inline-block;
                            label{
                                .custom-radio{
                                    .custom-control-label{
                                        margin-top: 4px;
                                        margin-left: 10px;
                                        font-weight: 500;
                                        font-size: 15px;
                                        text-transform: uppercase;
                                        color: #545454;

                                    }
                                }
                            }
                        }
                    }
                }
                .display-type{
                    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.0580276);
                    padding: 28px;
                    >fieldset{
                        select{
                            background-color: #F8F8F8;
                            border-radius: 0;
                            height: 41px;
                            color: black;
                            border: none;
                            max-width: 342px;
                            font-size: 16px;
                        }
                    }
                    .form-group{
                        >label{
                            color: #636363;
                            font-size: 16px;
                            margin-bottom: 15px;
                        }
                        textarea{
                            background: #FFFAE8;
                            border-radius: 0;
                            border: none;
                            padding: 21px 27px;
                            color: #636363;
                            font-size: 14px;
                        }
                        
                    }
                    .display-type-footer{
                        margin-top: 30px;
                        margin-left: 5px;
                        a{
                            margin-right: 70px;
                            color: #00ADFF;
                            font-weight: 500;
                            font-size: 16px;
                        }
                    }
                }
            }
            &.notes{
                .card-body{
                    .custom-text-title{
                        margin-bottom: 20px;
                    }
                    >div{
                        margin-bottom: 12px;
                        >label{
                            color: #545454;
                            font-weight: 500;
                            font-size: 15px;
                            text-transform: uppercase;
                            .span-unset{
                                margin-left: 5px;
                                text-transform: lowercase;
                            }
                        }
                        &.form-side{
                            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.0580276);
                            padding: 30px;
                            margin-bottom: 30px;
                            .form-group{
                                label{
                                    span{
                                        font-weight: 500;
                                        font-size: 15px;
                                        color: #545454;
                                    }
                                }
                                input{
                                    background: #F8F8F8; 
                                    border-radius: 0;
                                    border: none;
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}