.auth-page{
  .box-shadow-2 {
    width: 25rem;
  }

  .card-title {
    .p-1 {
      img {
        width: 200px;
      }
    }
  }

  .card-content {
    .card-body {
      .error {
        color: red;
        padding-bottom: 10px
      }
    }
  }
}