.profile-card{
   .card-header{
       display: flex;
       justify-content: space-between;
       padding-bottom: 0;
        h4{
            font-weight: 500;
            font-size: 16px;
            color: black;
        }
        >div{
            width: unset;
            >button{
                padding: 3px 12px;
                margin: 0 5px;
                font-weight: 500;
                font-size: 14px;
                &:first-child{
                    border-color: #00ADFF;
                    color: #00ADFF;
                    &:hover{
                        border-color: #008bcc;
                        background-color: #008bcc;
                        color: white; 
                    }
                }
                &:last-child{
                    border-color: #E0186C;
                    color: #E0186C;
                    &:hover{
                        border-color: #ad0048;
                        background-color: #ad0048;
                        color: white; 
                    }
                }
            }
        }
    }
    .card-body{
        >p{
            span{
                font-weight: 600;
            }
        }
    }
}