.templates{
    width: 100%;
    margin-top: -40px;
    >.form-group{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        padding-left: 0 !important;
        fieldset{
            margin-bottom: 40px;
            .btn-group{
                >a{
                    box-shadow: none !important;
                    border-radius: 0 !important;
                    border: 1px solid black !important;
                    color: black;
                    &:hover{
                        background-color: black !important;
                        color: white !important;
                    }
                }
                .active{
                    background-color: black !important;
                    color: white;
                }
            }
        }
        .template-themes{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            border: none;
            max-width: 1100px;
            li{
                list-style: none;
                padding: 7px 16px;
                margin: 0 8px 10px;
                cursor: pointer;
                color: #737373;
                font-size: 16px;
                &.active{
                    background-color: #00C87F !important;
                    color: white;
                }
                &:hover{
                    background-color: #00c87f28;
                }
            }
        }
    }
    .row{
        .template-responsive{
            position: relative;
            margin-bottom: 40px;
            transform: translate(0.5s);
            .template-heading-elements{
                position: absolute;
                top: 10px;
                right: 10px;
                i{
                    font-size: 22px;
                    &:hover{
                        color: #000;
                    }
                }
            }
            .card-img-overlay{
                background-color: #1e1e1e80;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &:hover{
                .card-btn{
                    display: flex;
                }
            }
            .card{
                margin-bottom: 0;
                background-color: #1e1e1e80 !important;
                border-radius: 0;
            }
            .card-btn{
                display: none;
                position: absolute;
                bottom: -20px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 99999;
                button{
                    border-radius: 17px !important;
                    padding: 9px 22px;
                    &.btn-white{
                        background-color: white;
                        color: black;
                        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.300357);
                        color: #4A4A4A;
                        font-weight: 500;
                        font-size: 15px;
                        &:hover{
                            color: black !important;
                        }
                    }
                    &.btn-blue{
                        background: #00ADFF;
                        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.300357);
                        border-color: #00ADFF !important;
                    }
                }
            }
            .card-content{
                height: 100%;
                img{
                    height: 100%;
                }
                p{
                    font-size: 14px;
                    line-height: 16px;
                    text-align: center;
                    color: #FFFFFF;
                    opacity: 0.76;
                }
                h4{
                    font-size: 20px;
                    margin-bottom: 16px;
                    font-weight: 500;
                    font-family: Roboto;
                }
                .subdivtext{
                    background-color: none !important;
                }
            }
        }
    }
}
