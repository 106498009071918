.quiz-form-content .alert-window{
    z-index: 9999999999999999 !important;
}

.quiz-form-content{
    >form{
        >.card-content{
            >.card-body{
                padding: 0;
                padding-top: 10px;
            }
        }
    }
    .alert-window{
        position: fixed;
        top: 20px;
        right: 20px;
        background-color: #9c0000a2;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 350px;
        padding: 20px 30px;
        p{
            color: white;
            font-size: 18px;
        }
    }
    .quiz-stage-footer{
        position: fixed !important;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.0580276);
        height: 84px;
        display: flex !important;
        align-items: center;
        margin: 0;
        padding: 0 !important;
        z-index: 9999;
        >ul{
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 1280px;
            width: 100%;
            margin: auto;
            padding: 0;
            li{
                display: flex;
                align-items: center;
                .btn-create{
                    border: 3px solid !important;
                    border-radius: 19px !important;
                    padding: 0 24px !important;
                    height: 38px !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-transform: uppercase;
                    input{
                        padding: 0 !important;
                        margin: 0;
                        text-transform: uppercase;
                    }
                }
                .btn-create{
                    &.btn-pink{
                        border-color: #E0186C;
                        color: #E0186C;
                        background-color: white !important;
                        &:hover{
                            color: white;
                            background-color: #E0186C !important;
                            border: 3px solid #E0186C !important;
                        }
                    }
                }
                &.quiz-see{
                    >.previewOpen{
                        margin: 0 30px;
                        &:hover{
                            i,span{
                                color: black;
                            }
                        }
                    }
                    i,span{
                        color: #5E5E5E;
                        font-size: 14px;
                        font-weight: 500;
                    }
                    span{
                        margin-left: 10px;
                        text-transform: uppercase;
                    }
                }
                .create-quiz-button{
                    margin-left: 15px;
                    outline-style: none;
                    button{
                        &.blue{
                            color: #00ADFF !important;
                            border-color: #00ADFF !important;
                            text-transform: inherit;
                            outline: none !important;
                            &:hover{
                                background-color: #00ADFF !important;
                                color: white !important;
                                border: 3px solid #00ADFF !important;
                            }
                            i{
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
            >li:first-child{
                .btn-create{
                    border-color: #00CF8A;
                    color: #00CF8A;
                    margin-right: 20px;
                    &.disabled{
                        opacity: 0.5;
                        &:hover{
                            color: #00CF8A;
                            background-color: white;
                            border: 3px solid #00CF8A !important;
                        }
                    }
                    &:hover{
                        color: white;
                        border: 3px solid #00CF8A !important;
                    }
                }
            }
        }
    }
}