* {
  font-family: 'Roboto', sans-serif !important;
}
html {
  font-size: 14px;
  height: 100%; }
  html body {
    /* height: 100%; */
    background-color: white;
    direction: ltr; }
    html body.fixed-navbar {
      padding-top: 5rem; }
    html body a {
      color: #1E9FF2; }
      html body a:hover {
        color: #0c84d1; }
    html body .content {
      padding: 0;
      position: relative;
      transition: 300ms ease all;
      backface-visibility: hidden;
      min-height: calc(100% - 32px); }
      html body .content.app-content {
        overflow: hidden; }
      html body .content .content-wrapper {
        padding: 0;
        min-height: calc(92vh - 70px);
        max-width: 1280px;
        margin: auto;
      }
        html body .content .content-wrapper .content-header-title {
          font-weight: 500;
          letter-spacing: 1px;
          color: #464855; }
    html body .la {
      font-size: 1.4rem; }
    html body[data-col="1-column"]:not(.vertical-content-menu) .content,
    html body[data-col="1-column"]:not(.vertical-content-menu) .footer {
      margin-left: 0px !important; }
    html body[data-col="1-column"].horizontal-layout .content,
    html body[data-col="1-column"].horizontal-layout .footer {
      margin: 0 auto !important; }
    html body[data-col="1-column"].vertical-content-menu .content-body {
      margin-left: 0px !important; }
    html body.boxed-layout {
      padding-left: 0;
      padding-right: 0; }
    html body.bg-full-screen-image {
      /*background: url(../../app-assets/images/backgrounds/bg-2.jpg) no-repeat center center fixed;*/
      background-size: cover; }
    html body .pace .pace-progress {
      background: #FF4961; }

/*
* Blank page
*/
.blank-page .content-wrapper {
  padding: 0 !important; }
  .blank-page .content-wrapper .flexbox-container {
    display: flex;
    align-items: center;
    height: 100vh; }

.app-content.center-layout {
  overflow: hidden; }

@media (max-width: 767.98px) {
  html body .content .content-wrapper {
    padding: 1rem; }
  html body footer {
    text-align: center; } }

/*
* Col 3 layout for detached and general type
*/
@media (min-width: 992px) {
  body .content-right {
    width: calc(100% - 300px);
    float: right; }
  body .content-left {
    width: calc(100% - 300px);
    float: left; }
  body .content-detached {
    width: 100%; }
    body .content-detached.content-right {
      float: right;
      margin-left: -300px; }
      body .content-detached.content-right .content-body {
        margin-left: 320px; }
    body .content-detached.content-left {
      float: left;
      margin-right: -300px; }
      body .content-detached.content-left .content-body {
        margin-right: 320px; }
  .sidebar-right.sidebar-sticky {
    float: right !important;
    margin-left: -300px;
    width: 300px !important; }
  [data-col="content-left-sidebar"] .sticky-wrapper {
    float: left; }
  .vertical-content-menu .content .sidebar-left {
    margin-left: 288px; }
  .vertical-content-menu .content .content-detached.content-right {
    float: right;
    width: calc(100% - 300px);
    margin-left: -300px; }
    .vertical-content-menu .content .content-detached.content-right .content-body {
      margin-left: 295px; }
  .vertical-content-menu .content .content-detached.content-left {
    float: left;
    width: 100%;
    margin-right: -300px; }
    .vertical-content-menu .content .content-detached.content-left .content-body {
      margin-right: 320px; } }

/*=========================================================================================
	File Name: sidebar.scss
	Description: content sidebar specific scss.
	----------------------------------------------------------------------------------------
	Item Name: Modern Admin - Clean Bootstrap 4 Dashboard HTML Template
	Version: 1.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.sidebar {
  position: relative;
  width: 100%; }
  @media (min-width: 992px) {
    .sidebar {
      vertical-align: top;
      width: 300px; } }

.sidebar-fixed {
  position: fixed;
  height: 100%;
  overflow: scroll; }

@media (min-width: 992px) {
  .sidebar-left {
    float: left; }
  .sidebar-right {
    float: right; } }

.horizontal-menu-padding .header-navbar .navbar-container {
  padding: 0; }

.header-navbar .navbar-container {
  height: auto; }

footer.footer {
  padding: 0.8rem; }

footer.navbar-shadow {
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.1); }

footer.navbar-border {
  border-top: 1px solid #E4E7ED; }

footer.footer-transparent {
  border: none; }

footer.footer-light {
  background: #FFFFFF; }

footer.footer-dark {
  background: #2C303B;
  color: #fff; }

/*=========================================================================================
	File Name: navigations.scss
	Description: Common mixin for menus, contain dark and light version scss.
	----------------------------------------------------------------------------------------
	Item Name: Modern Admin - Clean Bootstrap 4 Dashboard HTML Template
	Version: 1.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.main-menu {
  z-index: 1000;
  position: absolute;
  display: table-cell; }
  .main-menu.menu-light {
    color: #6B6F82;
    background: #FFFFFF;
    border-right: 1px solid #E4E7ED; }
    .main-menu.menu-light .main-menu-header {
      padding: 20px; }
      .main-menu.menu-light .main-menu-header .menu-search {
        background: #e6e6e6;
        padding: 0.5rem 1rem;
        color: #545766; }
        .main-menu.menu-light .main-menu-header .menu-search:focus {
          border-color: #d9d9d9; }
    .main-menu.menu-light .navigation {
      background: #FFFFFF; }
      .main-menu.menu-light .navigation .navigation-header {
        color: #6B6F82;
        padding: 30px 20px 8px 20px; }
        .main-menu.menu-light .navigation .navigation-header span {
          font-weight: 500; }
      .main-menu.menu-light .navigation li a {
        color: #6B6F82;
        transition: all 0.2s ease; }
        .main-menu.menu-light .navigation li a i, .main-menu.menu-light .navigation li a span {
          transition: all 0.2s ease; }
        .main-menu.menu-light .navigation li a span {
          display: inline-block; }
        .main-menu.menu-light .navigation li a span.menu-sub-title {
          color: #bdbfc9; }
      .main-menu.menu-light .navigation li.hover > a, .main-menu.menu-light .navigation li.open > a, .main-menu.menu-light .navigation li.active > a {
        color: #FFFFFF; }
      .main-menu.menu-light .navigation > li {
        padding: 0; }
        .main-menu.menu-light .navigation > li > a {
          padding: 12px 18px 12px 14px; }
          .main-menu.menu-light .navigation > li > a .label {
            margin-top: 4px;
            margin-right: 5px; }
          .main-menu.menu-light .navigation > li > a i {
            position: relative;
            top: 3px; }
        .main-menu.menu-light .navigation > li.open > a {
          color: #545766;
          background: whitesmoke;
          border-right: 4px solid #1E9FF2; }
        .main-menu.menu-light .navigation > li.open .hover > a {
          transform: translateX(-4px); }
        .main-menu.menu-light .navigation > li:not(.open) > ul {
          display: none; }
        .main-menu.menu-light .navigation > li.hover > a, .main-menu.menu-light .navigation > li:hover > a, .main-menu.menu-light .navigation > li.active > a {
          color: #545766; }
          .main-menu.menu-light .navigation > li.hover > a > i, .main-menu.menu-light .navigation > li:hover > a > i, .main-menu.menu-light .navigation > li.active > a > i {
            transform: translateX(6px); }
          .main-menu.menu-light .navigation > li.hover > a > span, .main-menu.menu-light .navigation > li:hover > a > span, .main-menu.menu-light .navigation > li.active > a > span {
            transform: translateX(4px); }
          .main-menu.menu-light .navigation > li.hover > a.mm-next, .main-menu.menu-light .navigation > li:hover > a.mm-next, .main-menu.menu-light .navigation > li.active > a.mm-next {
            background-color: inherit; }
        .main-menu.menu-light .navigation > li.active > a {
          color: #6B6F82;
          font-weight: 700;
          background: #f0f0f0; }
        .main-menu.menu-light .navigation > li .active {
          background: #f0f0f0; }
          .main-menu.menu-light .navigation > li .active > a {
            color: #85899b;
            font-weight: 700;
            background: #f0f0f0; }
          .main-menu.menu-light .navigation > li .active .hover > a {
            background: whitesmoke; }
        .main-menu.menu-light .navigation > li ul {
          padding: 0;
          margin: 0;
          font-size: 1rem; }
          .main-menu.menu-light .navigation > li ul li {
            color: #6B6F82;
            background: transparent; }
            .main-menu.menu-light .navigation > li ul li > a {
              padding: 8px 18px 8px 54px; }
          .main-menu.menu-light .navigation > li ul .has-sub:not(.open) > ul {
            display: none; }
          .main-menu.menu-light .navigation > li ul .open > a {
            color: #6B6F82; }
          .main-menu.menu-light .navigation > li ul .open > ul {
            display: block; }
            .main-menu.menu-light .navigation > li ul .open > ul li > a {
              padding: 8px 18px 8px 64px; }
            .main-menu.menu-light .navigation > li ul .open > ul .open > ul {
              display: block; }
              .main-menu.menu-light .navigation > li ul .open > ul .open > ul li > a {
                padding: 8px 18px 8px 74px; }
          .main-menu.menu-light .navigation > li ul .hover > a, .main-menu.menu-light .navigation > li ul:hover > a {
            color: #74798d; }
          .main-menu.menu-light .navigation > li ul .active {
            background: transparent; }
            .main-menu.menu-light .navigation > li ul .active > a {
              color: #85899b;
              font-weight: 700; }
            .main-menu.menu-light .navigation > li ul .active .hover > a {
              background-color: transparent; }
        .main-menu.menu-light .navigation > li > ul {
          background: #FFFFFF; }
    .main-menu.menu-light ul.menu-popout {
      background: #FFFFFF; }
      .main-menu.menu-light ul.menu-popout li a {
        color: #6B6F82; }
        .main-menu.menu-light ul.menu-popout li a span.menu-sub-title {
          color: #bdbfc9; }
      .main-menu.menu-light ul.menu-popout .has-sub:not(.open) > ul {
        display: none; }
      .main-menu.menu-light ul.menu-popout .open > a {
        color: #6B6F82; }
      .main-menu.menu-light ul.menu-popout .open > ul {
        display: block; }
        .main-menu.menu-light ul.menu-popout .open > ul .open {
          background: #e8e8e8; }
          .main-menu.menu-light ul.menu-popout .open > ul .open > ul {
            display: block;
            background: #e8e8e8; }
      .main-menu.menu-light ul.menu-popout .hover > a, .main-menu.menu-light ul.menu-popout:hover > a {
        color: #74798d; }
      .main-menu.menu-light ul.menu-popout .active {
        font-weight: 700; }
        .main-menu.menu-light ul.menu-popout .active > a {
          color: #85899b; }
        .main-menu.menu-light ul.menu-popout .active .hover > a, .main-menu.menu-light ul.menu-popout .active :hover > a {
          background-color: transparent; }
  .main-menu.menu-dark {
    color: #dcdcdc;
    background: #2C303B; }
    .main-menu.menu-dark .main-menu-header {
      padding: 20px; }
      .main-menu.menu-dark .main-menu-header .menu-search {
        background: #16181e;
        padding: 0.5rem 1rem;
        color: #c3c3c3; }
        .main-menu.menu-dark .main-menu-header .menu-search:focus {
          border-color: #0b0c0f; }
    .main-menu.menu-dark .navigation {
      background: #2C303B; }
      .main-menu.menu-dark .navigation .navigation-header {
        color: #dcdcdc;
        padding: 30px 20px 8px 20px; }
        .main-menu.menu-dark .navigation .navigation-header span {
          font-weight: 500; }
      .main-menu.menu-dark .navigation li a {
        color: #dcdcdc;
        transition: all 0.2s ease; }
        .main-menu.menu-dark .navigation li a i, .main-menu.menu-dark .navigation li a span {
          transition: all 0.2s ease; }
        .main-menu.menu-dark .navigation li a span {
          display: inline-block; }
        .main-menu.menu-dark .navigation li a span.menu-sub-title {
          color: white; }
      .main-menu.menu-dark .navigation li.hover > a, .main-menu.menu-dark .navigation li.open > a, .main-menu.menu-dark .navigation li.active > a {
        color: #FFFFFF; }
      .main-menu.menu-dark .navigation > li {
        padding: 0; }
        .main-menu.menu-dark .navigation > li > a {
          padding: 12px 18px 12px 14px; }
          .main-menu.menu-dark .navigation > li > a .label {
            margin-top: 4px;
            margin-right: 5px; }
          .main-menu.menu-dark .navigation > li > a i {
            position: relative;
            top: 3px; }
        .main-menu.menu-dark .navigation > li.open > a {
          color: #c3c3c3;
          background: #23262f;
          border-right: 4px solid #1E9FF2; }
        .main-menu.menu-dark .navigation > li.open .hover > a {
          transform: translateX(-4px); }
        .main-menu.menu-dark .navigation > li:not(.open) > ul {
          display: none; }
        .main-menu.menu-dark .navigation > li.hover > a, .main-menu.menu-dark .navigation > li:hover > a, .main-menu.menu-dark .navigation > li.active > a {
          color: #c3c3c3; }
          .main-menu.menu-dark .navigation > li.hover > a > i, .main-menu.menu-dark .navigation > li:hover > a > i, .main-menu.menu-dark .navigation > li.active > a > i {
            transform: translateX(6px); }
          .main-menu.menu-dark .navigation > li.hover > a > span, .main-menu.menu-dark .navigation > li:hover > a > span, .main-menu.menu-dark .navigation > li.active > a > span {
            transform: translateX(4px); }
          .main-menu.menu-dark .navigation > li.hover > a.mm-next, .main-menu.menu-dark .navigation > li:hover > a.mm-next, .main-menu.menu-dark .navigation > li.active > a.mm-next {
            background-color: inherit; }
        .main-menu.menu-dark .navigation > li.active > a {
          color: #dcdcdc;
          font-weight: 700;
          background: #1f2229; }
        .main-menu.menu-dark .navigation > li .active {
          background: #1f2229; }
          .main-menu.menu-dark .navigation > li .active > a {
            color: #f6f6f6;
            font-weight: 700;
            background: #1f2229; }
          .main-menu.menu-dark .navigation > li .active .hover > a {
            background: #23262f; }
        .main-menu.menu-dark .navigation > li ul {
          padding: 0;
          margin: 0;
          font-size: 1rem; }
          .main-menu.menu-dark .navigation > li ul li {
            color: #dcdcdc;
            background: transparent; }
            .main-menu.menu-dark .navigation > li ul li > a {
              padding: 8px 18px 8px 54px; }
          .main-menu.menu-dark .navigation > li ul .has-sub:not(.open) > ul {
            display: none; }
          .main-menu.menu-dark .navigation > li ul .open > a {
            color: #dcdcdc; }
          .main-menu.menu-dark .navigation > li ul .open > ul {
            display: block; }
            .main-menu.menu-dark .navigation > li ul .open > ul li > a {
              padding: 8px 18px 8px 64px; }
            .main-menu.menu-dark .navigation > li ul .open > ul .open > ul {
              display: block; }
              .main-menu.menu-dark .navigation > li ul .open > ul .open > ul li > a {
                padding: 8px 18px 8px 74px; }
          .main-menu.menu-dark .navigation > li ul .hover > a, .main-menu.menu-dark .navigation > li ul:hover > a {
            color: #e6e6e6; }
          .main-menu.menu-dark .navigation > li ul .active {
            background: transparent; }
            .main-menu.menu-dark .navigation > li ul .active > a {
              color: #f6f6f6;
              font-weight: 700; }
            .main-menu.menu-dark .navigation > li ul .active .hover > a {
              background-color: transparent; }
        .main-menu.menu-dark .navigation > li > ul {
          background: #2C303B; }
    .main-menu.menu-dark ul.menu-popout {
      background: #2C303B; }
      .main-menu.menu-dark ul.menu-popout li a {
        color: #dcdcdc; }
        .main-menu.menu-dark ul.menu-popout li a span.menu-sub-title {
          color: white; }
      .main-menu.menu-dark ul.menu-popout .has-sub:not(.open) > ul {
        display: none; }
      .main-menu.menu-dark ul.menu-popout .open > a {
        color: #dcdcdc; }
      .main-menu.menu-dark ul.menu-popout .open > ul {
        display: block; }
        .main-menu.menu-dark ul.menu-popout .open > ul .open {
          background: #181b21; }
          .main-menu.menu-dark ul.menu-popout .open > ul .open > ul {
            display: block;
            background: #181b21; }
      .main-menu.menu-dark ul.menu-popout .hover > a, .main-menu.menu-dark ul.menu-popout:hover > a {
        color: #e6e6e6; }
      .main-menu.menu-dark ul.menu-popout .active {
        font-weight: 700; }
        .main-menu.menu-dark ul.menu-popout .active > a {
          color: #f6f6f6; }
        .main-menu.menu-dark ul.menu-popout .active .hover > a, .main-menu.menu-dark ul.menu-popout .active :hover > a {
          background-color: transparent; }
  .main-menu.menu-fixed {
    position: fixed;
    height: 100%;
    /* fallback if needed */
    top: 5rem;
    height: calc(100% - 5rem); }
    .main-menu.menu-fixed .main-menu-footer {
      position: fixed; }
  .main-menu.menu-shadow {
    box-shadow: 1px 0px 30px rgba(0, 0, 0, 0.1); }
  .main-menu.menu-border {
    border-right: 1px solid #E4E7ED; }
  .main-menu.menu-native-scroll .main-menu-content {
    overflow-y: scroll; }
  .main-menu.menu-bordered.menu-light .navigation > li {
    border-top: 1px solid #E4E7ED; }
  .main-menu.menu-bordered.menu-dark .navigation > li {
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
  .main-menu .main-menu-content {
    height: 100%;
    position: relative; }
  .main-menu ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .main-menu ul.navigation-main {
      overflow-x: hidden; }
  .main-menu a {
    outline: none; }
    .main-menu a:hover, .main-menu a:focus {
      text-decoration: none; }

.navigation {
  font-size: 1.1rem;
  letter-spacing: 0.2px;
  font-family: "Quicksand", Georgia, "Times New Roman", Times, serif;
  font-weight: 400;
  overflow-y: hidden;
  padding-bottom: 20px; }
  .navigation .navigation-header {
    font-family: "Quicksand", Georgia, "Times New Roman", Times, serif;
    font-weight: 400;
    line-height: 1.2;
    padding: 12px 22px;
    font-size: 1rem; }
  .navigation li {
    position: relative;
    /*white-space: nowrap;*/
  }
    .navigation li a {
      display: block;
      /* white-space: nowrap; */
      text-overflow: ellipsis;
      overflow: hidden; }
    .navigation li .is-hidden {
      /* transition: all 250ms ease-in;
            transform: scale(0);
            opacity: 0; */ }
    .navigation li .is-shown {
      /* transition: all 250ms ease-in;
            transform: scale(1);
            opacity: 1; */ }
    .navigation li.disabled a {
      cursor: not-allowed; }

.menu-popout li.disabled a {
  cursor: not-allowed; }

.dropdown-notification .notification-text {
  margin-bottom: 0.5rem; }

.dropdown-notification .notification-tag {
  position: relative;
  top: -28px;
  right: 20px; }

.main-menu-header .user-content {
  padding: 20px; }
  .main-menu-header .user-content .media {
    overflow: inherit; }
  .main-menu-header .user-content .media-body {
    vertical-align: bottom;
    opacity: 1;
    width: 150px;
    white-space: nowrap;
    transition: all .3s ease .15s; }

.main-menu-footer {
  position: relative;
  overflow: hidden;
  bottom: 0;
  display: block;
  z-index: 1000;
  color: #c5c9d4;
  background-color: #16181e; }
  .main-menu-footer.footer-open {
    max-height: 500px;
    transition: max-height 0.2s ease-in-out; }
  .main-menu-footer.footer-close {
    max-height: 15px;
    transition: max-height 0.2s ease-in-out; }
  .main-menu-footer a {
    color: #a8aebe; }
    .main-menu-footer a:hover, .main-menu-footer a:focus {
      color: #FFFFFF; }
  .main-menu-footer .header {
    height: 19px;
    border-bottom: 1px solid #21242c; }
  .main-menu-footer .toggle {
    transform: rotate(0deg);
    transition: -webkit-transform 0.2s ease-in-out; }
  .main-menu-footer .content {
    padding: 0; }
    .main-menu-footer .content .actions > a {
      display: block;
      float: left;
      width: 33.333333%;
      padding: 1rem 0;
      color: #a8aebe;
      text-align: center;
      border-top: 1px solid #21242c;
      border-left: 1px solid #21242c; }
      .main-menu-footer .content .actions > a > span {
        font-size: 1.35rem; }
      .main-menu-footer .content .actions > a:hover, .main-menu-footer .content .actions > a:focus {
        color: #FFFFFF; }

body.vertical-layout.vertical-menu.menu-expanded .main-menu-footer .content {
  margin-left: 0; }

.vertical-layout.menu-expanded .menu-icon-right ul.navigation li > a:after, .vertical-overlay-menu .menu-icon-right ul.navigation li > a:after {
  left: 8px;
  right: initial !important; }

.vertical-layout.menu-expanded .menu-icon-right ul.navigation li > a > i, .vertical-overlay-menu .menu-icon-right ul.navigation li > a > i {
  float: right !important;
  margin: 0px -12px  0px 0px !important; }

.horizontal-layout .navbar-icon-right ul.nav > li > a:after {
  float: left;
  position: relative;
  top: 27px;
  left: -16px; }

.horizontal-layout .navbar-icon-right ul.nav > li > a > i {
  float: right;
  margin: 0px 4px 0 8px; }

.horizontal-layout .navbar-fixed {
  z-index: 999 !important; }

@media (max-width: 767.98px) {
  .menu-hide .main-menu,
  .menu-open .main-menu {
    transition: transform .25s, top .35s, height .35s; }
  .main-menu {
    transform: translate3d(-240px, 0, 0);
    backface-visibility: hidden;
    perspective: 1000; }
  .menu-open .main-menu {
    transform: translate3d(0, 0, 0); } }

@media (max-width: 991.98px) {
  .horizontal-layout.vertical-overlay-menu .main-menu-content {
    padding: 0; }
    .horizontal-layout.vertical-overlay-menu .main-menu-content .mega-dropdown-menu {
      margin: 0;
      width: 100%; }
      .horizontal-layout.vertical-overlay-menu .main-menu-content .mega-dropdown-menu > li {
        padding: 0; }
  .horizontal-layout.vertical-overlay-menu .navbar .navbar-container {
    margin-left: 0px; } }

.display-inline {
  display: inline !important; }

.display-block {
  display: block !important; }

.display-inline-block {
  display: inline-block !important; }

.display-hidden {
  display: none !important; }

.display-table-cell {
  display: table-cell !important; }

.position-top-0 {
  top: 0; }

.position-right-0 {
  right: 0; }

.position-bottom-0 {
  bottom: 0; }

.position-left-0 {
  left: 0; }

.zindex-1 {
  z-index: 1 !important; }

.zindex-2 {
  z-index: 2 !important; }

.zindex-3 {
  z-index: 3 !important; }

.zindex-4 {
  z-index: 4 !important; }

.zindex-0 {
  z-index: 0 !important; }

.zindex-minus-1 {
  z-index: -1 !important; }

.zindex-minus-2 {
  z-index: -2 !important; }

.zindex-minus-3 {
  z-index: -3 !important; }

.zindex-minus-4 {
  z-index: -4 !important; }

.no-edge-top {
  top: 0 !important; }

.no-edge-bottom {
  bottom: 0 !important; }

.no-edge-left {
  left: 0 !important; }

.no-edge-right {
  right: 0 !important; }

.cursor-pointer {
  cursor: pointer; }

.cursor-move {
  cursor: move; }

.cursor-default {
  cursor: default; }

.cursor-progress {
  cursor: progress; }

.cursor-not-allowed {
  cursor: not-allowed; }

.overflow-hidden {
  overflow: hidden; }

.overflow-visible {
  overflow: visible; }

.overflow-auto {
  overflow: auto; }

.overflow-scroll {
  overflow: scroll; }

.overflow-x-scroll {
  overflow: scroll; }

.overflow-y-scroll {
  overflow: scroll; }

.bullets-inside {
  list-style: inside; }

.list-style-circle {
  list-style: circle; }

.list-style-square {
  list-style: square; }

.list-style-icons {
  padding-left: 10px;
  margin-left: 0;
  list-style: none; }
  .list-style-icons > li i {
    float: left;
    width: 1em;
    margin: 0 6px 0 0; }

.border {
  border: 1px solid; }

.border-top {
  border-top: 1px solid; }

.border-bottom {
  border-bottom: 1px solid; }

.border-left {
  border-left: 1px solid; }

.border-right {
  border-right: 1px solid; }

.border-2 {
  border-width: 2px !important; }

.border-top-2 {
  border-top-width: 2px !important; }

.border-bottom-2 {
  border-bottom-width: 2px !important; }

.border-left-2 {
  border-left-width: 2px !important; }

.border-right-2 {
  border-right-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-top-3 {
  border-top-width: 3px !important; }

.border-bottom-3 {
  border-bottom-width: 3px !important; }

.border-left-3 {
  border-left-width: 3px !important; }

.border-right-3 {
  border-right-width: 3px !important; }

.no-border-top-radius {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.no-border-bottom-radius {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.no-border-top-left-radius {
  border-top-left-radius: 0 !important; }

.no-border-top-right-radius {
  border-top-right-radius: 0 !important; }

.no-border-bottom-left-radius {
  border-bottom-left-radius: 0 !important; }

.no-border-bottom-right-radius {
  border-bottom-right-radius: 0 !important; }

.box-shadow-0 {
  box-shadow: none !important; }

.box-shadow-1 {
  box-shadow: 0 7px 12px 0 rgba(62, 57, 107, 0.16); }

.box-shadow-2 {
  box-shadow: 0 10px 18px 0 rgba(62, 57, 107, 0.2); }

.box-shadow-3 {
  box-shadow: 0 14px 24px 0 rgba(62, 57, 107, 0.26); }

.box-shadow-4 {
  box-shadow: 0 16px 28px 0 rgba(62, 57, 107, 0.3); }

.box-shadow-5 {
  box-shadow: 0 27px 24px 0 rgba(62, 57, 107, 0.36); }

.fit {
  max-width: 100% !important; }

.half-width {
  width: 50% !important; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.width-50 {
  width: 50px !important; }

.width-100 {
  width: 100px !important; }

.width-150 {
  width: 150px !important; }

.width-200 {
  width: 200px !important; }

.width-250 {
  width: 250px !important; }

.width-300 {
  width: 300px !important; }

.width-350 {
  width: 350px !important; }

.width-400 {
  width: 400px !important; }

.width-450 {
  width: 450px !important; }

.width-500 {
  width: 500px !important; }

.width-550 {
  width: 550px !important; }

.width-600 {
  width: 600px !important; }

.width-650 {
  width: 650px !important; }

.width-700 {
  width: 700px !important; }

.width-750 {
  width: 750px !important; }

.width-800 {
  width: 800px !important; }

.width-5-per {
  width: 5% !important; }

.width-10-per {
  width: 10% !important; }

.width-15-per {
  width: 15% !important; }

.width-20-per {
  width: 20% !important; }

.width-25-per {
  width: 25% !important; }

.width-30-per {
  width: 30% !important; }

.width-35-per {
  width: 35% !important; }

.width-40-per {
  width: 40% !important; }

.width-45-per {
  width: 45% !important; }

.width-50-per {
  width: 50% !important; }

.width-55-per {
  width: 55% !important; }

.width-60-per {
  width: 60% !important; }

.width-65-per {
  width: 65% !important; }

.width-70-per {
  width: 70% !important; }

.width-75-per {
  width: 75% !important; }

.width-80-per {
  width: 80% !important; }

.width-90-per {
  width: 90% !important; }

.width-95-per {
  width: 95% !important; }

.height-50 {
  height: 50px !important; }

.height-75 {
  height: 75px !important; }

.height-100 {
  height: 100px !important; }

.height-150 {
  height: 150px !important; }

.height-200 {
  height: 200px !important; }

.height-250 {
  height: 250px !important; }

.height-300 {
  height: 300px !important; }

.height-350 {
  height: 350px !important; }

.height-400 {
  height: 400px !important; }

.height-450 {
  height: 450px !important; }

.height-500 {
  height: 500px !important; }

.height-550 {
  height: 550px !important; }

.height-600 {
  height: 600px !important; }

.height-650 {
  height: 650px !important; }

.height-700 {
  height: 700px !important; }

.height-750 {
  height: 750px !important; }

.height-800 {
  height: 800px !important; }

.height-5-per {
  height: 5% !important; }

.height-10-per {
  height: 10% !important; }

.height-15-per {
  height: 15% !important; }

.height-20-per {
  height: 20% !important; }

.height-25-per {
  height: 25% !important; }

.height-30-per {
  height: 30% !important; }

.height-35-per {
  height: 35% !important; }

.height-40-per {
  height: 40% !important; }

.height-45-per {
  height: 45% !important; }

.height-50-per {
  height: 50% !important; }

.height-55-per {
  height: 55% !important; }

.height-60-per {
  height: 60% !important; }

.height-65-per {
  height: 65% !important; }

.height-70-per {
  height: 70% !important; }

.height-75-per {
  height: 75% !important; }

.height-80-per {
  height: 80% !important; }

.full-height-vh-with-nav {
  height: calc(100vh - 5rem - 4rem); }

.full-height-vh {
  height: 100vh; }

.line-height-1 {
  line-height: 1 !important; }

.line-height-2 {
  line-height: 2 !important; }

.rotate-45 {
  transform: rotate(45deg); }

.rotate-45-inverse {
  transform: rotate(-45deg); }

.rotate-90 {
  transform: rotate(45deg); }

.rotate-90-inverse {
  transform: rotate(-45deg); }

.rotate-180 {
  transform: rotate(45deg); }

.rotate-180-inverse {
  transform: rotate(-45deg); }

.pull-up {
  transition: all 0.25s ease; }
  .pull-up:hover {
    transform: translateY(-4px) scale(1.02);
    box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
    z-index: 999; }

.spinner {
  display: inline-block;
  animation: spin 1s linear infinite; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.spinner-reverse {
  display: inline-block;
  animation: spin-reverse 1s linear infinite; }

@keyframes spin-reverse {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(-360deg); } }

.bg-cover {
  background-size: cover !important; }

.background-repeat {
  background-repeat: repeat !important; }

.background-no-repeat {
  background-repeat: no-repeat !important; }

.img-xl {
  width: 64px !important;
  height: 64px !important; }

.img-lg {
  width: 44px !important;
  height: 44px !important; }

.img-sm {
  width: 36px !important;
  height: 36px !important; }

.img-xs {
  width: 32px !important;
  height: 32px !important; }

.bg-hexagons {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23f7f7f7' fill-opacity='1' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); }

.bg-hexagons-danger {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23ff6576' fill-opacity='1' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); }

.avatar {
  position: relative;
  display: inline-block;
  width: 30px;
  white-space: nowrap;
  border-radius: 1000px;
  vertical-align: bottom; }
  .avatar i {
    position: absolute;
    right: -7px;
    bottom: 2px;
    width: 8px;
    height: 8px;
    border-radius: 100%; }
  .avatar span.text-circle {
    text-align: center;
    vertical-align: middle;
    color: #FFFFFF;
    font-size: 1.2rem;
    background: #BABFC7;
    display: table-cell; }
  .avatar img {
    width: 100%;
    max-width: 100%;
    height: auto;
    border: 0 none;
    border-radius: 1000px; }
  .avatar .badge-up {
    top: -8px;
    right: -11px; }

.avatar-online i {
  background-color: #28D094; }

.avatar-off i {
  background-color: #BABFC7; }

.avatar-busy i {
  background-color: #FF4961; }

.avatar-away i {
  background-color: #FF9149; }

.avatar-100 {
  width: 100px; }
  .avatar-100 span.text-circle {
    width: 100px;
    height: 100px; }
  .avatar-100 i {
    height: 20px;
    width: 20px; }

.avatar-50 {
  width: 50px; }
  .avatar-50 span.text-circle {
    width: 50px;
    height: 50px; }
  .avatar-50 i {
    height: 10px;
    width: 10px; }

.avatar-lg {
  width: 144px; }
  .avatar-lg span.text-circle {
    width: 144px;
    height: 144px; }
  .avatar-lg i {
    right: 0;
    bottom: 0;
    height: 20px;
    width: 20px; }

.avatar-md {
  width: 40px; }
  .avatar-md span.text-circle {
    width: 40px;
    height: 40px; }
  .avatar-md i {
    right: 0;
    bottom: 0;
    height: 10px;
    width: 10px; }

.avatar-sm {
  width: 32px; }
  .avatar-sm span.text-circle {
    width: 32px;
    height: 32px; }
  .avatar-sm i {
    height: 8px;
    width: 8px; }

.avatar-xs {
  width: 24px; }
  .avatar-xs span.text-circle {
    width: 24px;
    height: 24px; }
  .avatar-xs i {
    height: 7px;
    width: 7px; }

/*=========================================================================================
	File Name: search.scss
	Description: Search  functionality.
	----------------------------------------------------------------------------------------
	Item Name: Modern Admin - Clean Bootstrap 4 Dashboard HTML Template
	Version: 1.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.navbar-container a.nav-link-search {
  float: left; }

.navbar-container .search-input {
  float: left;
  padding-top: 1.2rem;
  width: 0; }
  .navbar-container .search-input .input {
    width: 0;
    border: none;
    background: none;
    transition: all 0.2s ease-out;
    line-height: 16px; }
  .navbar-container .search-input.open .input {
    width: 200px;
    padding: 5px 10px;
    outline: none;
    background: none;
    transition: all 0.3s ease-out; }

.navbar-light .search-input .input, .navbar-semi-dark .search-input .input {
  color: #2C303B; }
  .navbar-light .search-input .input::placeholder, .navbar-semi-dark .search-input .input::placeholder {
    color: #2C303B; }

.navbar-light .search-input.open .input, .navbar-semi-dark .search-input.open .input {
  color: #2C303B;
  border-bottom: 1px solid #2C303B; }

.navbar-dark .search-input .input, .navbar-semi-light .search-input .input {
  color: #F9FAFD; }
  .navbar-dark .search-input .input::placeholder, .navbar-semi-light .search-input .input::placeholder {
    color: #F9FAFD; }

.navbar-dark .search-input.open .input, .navbar-semi-light .search-input.open .input {
  color: #F9FAFD;
  border-bottom: 1px solid #F9FAFD; }

@media (max-width: 991.98px) {
  #navbar-mobile .search-input.open .input {
    width: 180px;
    color: #2C303B;
    border-bottom: 1px solid #2C303B;
    overflow: hidden;
    position: relative;
    background: #fff;
    z-index: 1;
    padding: 10px 10px;
    top: -5px; } }

.chart-container {
  position: relative;
  width: 100%; }

@media (max-width: 768px) {
  .chart-container {
    overflow-x: scroll;
    overflow-y: visible;
    max-width: 100%; } }

.chart {
  position: relative;
  display: block;
  width: 100%; }

.jqstooltip {
  box-sizing: content-box; }

[class*="bs-callout"] p {
  letter-spacing: 0.6px; }

[class*="bs-callout"] .media {
  border-radius: 0.25rem !important; }

[class*="bs-callout"].callout-bordered {
  border: 1px solid #ddd; }

[class*="bs-callout"].callout-border-left {
  border-left: 5px solid; }

[class*="bs-callout"].callout-border-right {
  border-right: 5px solid; }

[class*="bs-callout"] .callout-arrow-left {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }
  [class*="bs-callout"] .callout-arrow-left:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    right: -11%;
    border-left: 8px solid;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left-color: inherit;
    margin-top: -8px; }

[class*="bs-callout"] .callout-arrow-right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }
  [class*="bs-callout"] .callout-arrow-right:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    left: -11%;
    border-left: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left-color: inherit;
    border-right: 8px solid;
    border-right-color: inherit;
    margin-top: -8px; }

[class*="bs-callout"].callout-round {
  border-radius: 5rem !important; }
  [class*="bs-callout"].callout-round .media-left {
    border-top-left-radius: 5rem;
    border-bottom-left-radius: 5rem; }
  [class*="bs-callout"].callout-round .media-right {
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem; }

[class*="bs-callout"].callout-square {
  border-radius: 0 !important; }
  [class*="bs-callout"].callout-square .media, [class*="bs-callout"].callout-square .callout-arrow-left, [class*="bs-callout"].callout-square .callout-arrow-right {
    border-radius: 0 !important; }

.btn-social, .btn-social-icon {
  position: relative;
  padding-left: 3.95rem;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  .btn-social > :first-child, .btn-social-icon > :first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 2.95rem;
    line-height: 2.7rem;
    font-size: 1.6em;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.2); }
  .btn-social.btn-lg, .btn-lg.btn-social-icon {
    padding-left: 3.25rem; }
    .btn-social.btn-lg > :first-child, .btn-lg.btn-social-icon > :first-child {
      line-height: 3.7rem;
      width: 3rem;
      font-size: 1.8em; }
  .btn-social.btn-sm, .btn-sm.btn-social-icon {
    padding-left: 3.75rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
    .btn-social.btn-sm > :first-child, .btn-sm.btn-social-icon > :first-child {
      line-height: 2.2rem;
      width: 3rem;
      font-size: 1.4em; }
  .btn-social.btn-xs, .btn-xs.btn-social-icon {
    padding-left: 2.25rem;
    font-size: 0.75rem;
    line-height: 0.2rem; }
    .btn-social.btn-xs > :first-child, .btn-xs.btn-social-icon > :first-child {
      line-height: 2rem;
      width: 1.8rem;
      font-size: 1.2em; }

.btn-social-icon {
  height: 2.95rem;
  width: 2.95rem;
  padding: 0; }
  .btn-social-icon > :first-child {
    border: none;
    text-align: center;
    width: 100% !important; }
  .btn-social-icon.btn-lg {
    height: 4rem;
    width: 4rem;
    padding-left: 0;
    padding-right: 0; }
  .btn-social-icon.btn-sm {
    height: 2.5rem;
    width: 2.5rem;
    padding-left: 0;
    padding-right: 0; }
  .btn-social-icon.btn-xs {
    height: 2rem;
    width: 2rem;
    padding-left: 0;
    padding-right: 0; }

.btn-adn {
  background-color: #d87a68;
  color: #fff;
  background-color: #d87a68;
  border-color: #fff; }
  .btn-adn:hover {
    color: #fff;
    background-color: #d87a68;
    border-color: #d87a68; }
  .btn-adn:focus, .btn-adn.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-adn.disabled, .btn-adn:disabled {
    color: #fff;
    background-color: #d87a68;
    border-color: #fff; }
  .btn-adn:not(:disabled):not(.disabled):active, .btn-adn:not(:disabled):not(.disabled).active,
  .show > .btn-adn.dropdown-toggle {
    color: #fff;
    background-color: #ce563f;
    border-color: #dfdfdf; }
    .btn-adn:not(:disabled):not(.disabled):active:focus, .btn-adn:not(:disabled):not(.disabled).active:focus,
    .show > .btn-adn.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-bitbucket {
  background-color: #205081;
  color: #fff;
  background-color: #205081;
  border-color: #fff; }
  .btn-bitbucket:hover {
    color: #fff;
    background-color: #205081;
    border-color: #205081; }
  .btn-bitbucket:focus, .btn-bitbucket.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-bitbucket.disabled, .btn-bitbucket:disabled {
    color: #fff;
    background-color: #205081;
    border-color: #fff; }
  .btn-bitbucket:not(:disabled):not(.disabled):active, .btn-bitbucket:not(:disabled):not(.disabled).active,
  .show > .btn-bitbucket.dropdown-toggle {
    color: #fff;
    background-color: #163758;
    border-color: #dfdfdf; }
    .btn-bitbucket:not(:disabled):not(.disabled):active:focus, .btn-bitbucket:not(:disabled):not(.disabled).active:focus,
    .show > .btn-bitbucket.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-dropbox {
  background-color: #1087dd;
  color: #fff;
  background-color: #1087dd;
  border-color: #fff; }
  .btn-dropbox:hover {
    color: #fff;
    background-color: #1087dd;
    border-color: #1087dd; }
  .btn-dropbox:focus, .btn-dropbox.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-dropbox.disabled, .btn-dropbox:disabled {
    color: #fff;
    background-color: #1087dd;
    border-color: #fff; }
  .btn-dropbox:not(:disabled):not(.disabled):active, .btn-dropbox:not(:disabled):not(.disabled).active,
  .show > .btn-dropbox.dropdown-toggle {
    color: #fff;
    background-color: #0d6aad;
    border-color: #dfdfdf; }
    .btn-dropbox:not(:disabled):not(.disabled):active:focus, .btn-dropbox:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dropbox.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-facebook {
  background-color: #3b5998;
  color: #fff;
  background-color: #3b5998;
  border-color: #fff; }
  .btn-facebook:hover {
    color: #fff;
    background-color: #3b5998;
    border-color: #3b5998; }
  .btn-facebook:focus, .btn-facebook.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-facebook.disabled, .btn-facebook:disabled {
    color: #fff;
    background-color: #3b5998;
    border-color: #fff; }
  .btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active,
  .show > .btn-facebook.dropdown-toggle {
    color: #fff;
    background-color: #2d4373;
    border-color: #dfdfdf; }
    .btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus,
    .show > .btn-facebook.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-flickr {
  background-color: #ff0084;
  color: #fff;
  background-color: #ff0084;
  border-color: #fff; }
  .btn-flickr:hover {
    color: #fff;
    background-color: #ff0084;
    border-color: #ff0084; }
  .btn-flickr:focus, .btn-flickr.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-flickr.disabled, .btn-flickr:disabled {
    color: #fff;
    background-color: #ff0084;
    border-color: #fff; }
  .btn-flickr:not(:disabled):not(.disabled):active, .btn-flickr:not(:disabled):not(.disabled).active,
  .show > .btn-flickr.dropdown-toggle {
    color: #fff;
    background-color: #cc006a;
    border-color: #dfdfdf; }
    .btn-flickr:not(:disabled):not(.disabled):active:focus, .btn-flickr:not(:disabled):not(.disabled).active:focus,
    .show > .btn-flickr.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-foursquare {
  background-color: #f94877;
  color: #fff;
  background-color: #f94877;
  border-color: #fff; }
  .btn-foursquare:hover {
    color: #fff;
    background-color: #f94877;
    border-color: #f94877; }
  .btn-foursquare:focus, .btn-foursquare.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-foursquare.disabled, .btn-foursquare:disabled {
    color: #fff;
    background-color: #f94877;
    border-color: #fff; }
  .btn-foursquare:not(:disabled):not(.disabled):active, .btn-foursquare:not(:disabled):not(.disabled).active,
  .show > .btn-foursquare.dropdown-toggle {
    color: #fff;
    background-color: #f71752;
    border-color: #dfdfdf; }
    .btn-foursquare:not(:disabled):not(.disabled):active:focus, .btn-foursquare:not(:disabled):not(.disabled).active:focus,
    .show > .btn-foursquare.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-github {
  background-color: #444444;
  color: #fff;
  background-color: #444444;
  border-color: #fff; }
  .btn-github:hover {
    color: #fff;
    background-color: #444444;
    border-color: #444444; }
  .btn-github:focus, .btn-github.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-github.disabled, .btn-github:disabled {
    color: #fff;
    background-color: #444444;
    border-color: #fff; }
  .btn-github:not(:disabled):not(.disabled):active, .btn-github:not(:disabled):not(.disabled).active,
  .show > .btn-github.dropdown-toggle {
    color: #fff;
    background-color: #2b2b2b;
    border-color: #dfdfdf; }
    .btn-github:not(:disabled):not(.disabled):active:focus, .btn-github:not(:disabled):not(.disabled).active:focus,
    .show > .btn-github.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-google {
  background-color: #dd4b39;
  color: #fff;
  background-color: #dd4b39;
  border-color: #fff; }
  .btn-google:hover {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39; }
  .btn-google:focus, .btn-google.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-google.disabled, .btn-google:disabled {
    color: #fff;
    background-color: #dd4b39;
    border-color: #fff; }
  .btn-google:not(:disabled):not(.disabled):active, .btn-google:not(:disabled):not(.disabled).active,
  .show > .btn-google.dropdown-toggle {
    color: #fff;
    background-color: #c23321;
    border-color: #dfdfdf; }
    .btn-google:not(:disabled):not(.disabled):active:focus, .btn-google:not(:disabled):not(.disabled).active:focus,
    .show > .btn-google.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.label-text-before-contacts {
  margin-top: 15px;
}
      
.btn-instagram {
  background-color: #3f729b;
  color: #fff;
  background-color: #3f729b;
  border-color: #fff; }
  .btn-instagram:hover {
    color: #fff;
    background-color: #3f729b;
    border-color: #3f729b; }
  .btn-instagram:focus, .btn-instagram.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-instagram.disabled, .btn-instagram:disabled {
    color: #fff;
    background-color: #3f729b;
    border-color: #fff; }
  .btn-instagram:not(:disabled):not(.disabled):active, .btn-instagram:not(:disabled):not(.disabled).active,
  .show > .btn-instagram.dropdown-toggle {
    color: #fff;
    background-color: #305777;
    border-color: #dfdfdf; }
    .btn-instagram:not(:disabled):not(.disabled):active:focus, .btn-instagram:not(:disabled):not(.disabled).active:focus,
    .show > .btn-instagram.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-linkedin {
  background-color: #007bb6;
  color: #fff;
  background-color: #007bb6;
  border-color: #fff; }
  .btn-linkedin:hover {
    color: #fff;
    background-color: #007bb6;
    border-color: #007bb6; }
  .btn-linkedin:focus, .btn-linkedin.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-linkedin.disabled, .btn-linkedin:disabled {
    color: #fff;
    background-color: #007bb6;
    border-color: #fff; }
  .btn-linkedin:not(:disabled):not(.disabled):active, .btn-linkedin:not(:disabled):not(.disabled).active,
  .show > .btn-linkedin.dropdown-toggle {
    color: #fff;
    background-color: #005983;
    border-color: #dfdfdf; }
    .btn-linkedin:not(:disabled):not(.disabled):active:focus, .btn-linkedin:not(:disabled):not(.disabled).active:focus,
    .show > .btn-linkedin.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-microsoft {
  background-color: #2672ec;
  color: #fff;
  background-color: #2672ec;
  border-color: #fff; }
  .btn-microsoft:hover {
    color: #fff;
    background-color: #2672ec;
    border-color: #2672ec; }
  .btn-microsoft:focus, .btn-microsoft.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-microsoft.disabled, .btn-microsoft:disabled {
    color: #fff;
    background-color: #2672ec;
    border-color: #fff; }
  .btn-microsoft:not(:disabled):not(.disabled):active, .btn-microsoft:not(:disabled):not(.disabled).active,
  .show > .btn-microsoft.dropdown-toggle {
    color: #fff;
    background-color: #125acd;
    border-color: #dfdfdf; }
    .btn-microsoft:not(:disabled):not(.disabled):active:focus, .btn-microsoft:not(:disabled):not(.disabled).active:focus,
    .show > .btn-microsoft.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-odnoklassniki {
  background-color: #f4731c;
  color: #fff;
  background-color: #f4731c;
  border-color: #fff; }
  .btn-odnoklassniki:hover {
    color: #fff;
    background-color: #f4731c;
    border-color: #f4731c; }
  .btn-odnoklassniki:focus, .btn-odnoklassniki.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-odnoklassniki.disabled, .btn-odnoklassniki:disabled {
    color: #fff;
    background-color: #f4731c;
    border-color: #fff; }
  .btn-odnoklassniki:not(:disabled):not(.disabled):active, .btn-odnoklassniki:not(:disabled):not(.disabled).active,
  .show > .btn-odnoklassniki.dropdown-toggle {
    color: #fff;
    background-color: #d35b0a;
    border-color: #dfdfdf; }
    .btn-odnoklassniki:not(:disabled):not(.disabled):active:focus, .btn-odnoklassniki:not(:disabled):not(.disabled).active:focus,
    .show > .btn-odnoklassniki.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-openid {
  background-color: #f7931e;
  color: #2A2E30;
  background-color: #f7931e;
  border-color: #fff; }
  .btn-openid:hover {
    color: #2A2E30;
    background-color: #f7931e;
    border-color: #f7931e; }
  .btn-openid:focus, .btn-openid.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-openid.disabled, .btn-openid:disabled {
    color: #2A2E30;
    background-color: #f7931e;
    border-color: #fff; }
  .btn-openid:not(:disabled):not(.disabled):active, .btn-openid:not(:disabled):not(.disabled).active,
  .show > .btn-openid.dropdown-toggle {
    color: #fff;
    background-color: #da7908;
    border-color: #dfdfdf; }
    .btn-openid:not(:disabled):not(.disabled):active:focus, .btn-openid:not(:disabled):not(.disabled).active:focus,
    .show > .btn-openid.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-pinterest {
  background-color: #cb2027;
  color: #fff;
  background-color: #cb2027;
  border-color: #fff; }
  .btn-pinterest:hover {
    color: #fff;
    background-color: #cb2027;
    border-color: #cb2027; }
  .btn-pinterest:focus, .btn-pinterest.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-pinterest.disabled, .btn-pinterest:disabled {
    color: #fff;
    background-color: #cb2027;
    border-color: #fff; }
  .btn-pinterest:not(:disabled):not(.disabled):active, .btn-pinterest:not(:disabled):not(.disabled).active,
  .show > .btn-pinterest.dropdown-toggle {
    color: #fff;
    background-color: #9f191f;
    border-color: #dfdfdf; }
    .btn-pinterest:not(:disabled):not(.disabled):active:focus, .btn-pinterest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pinterest.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-reddit {
  background-color: #eff7ff;
  color: #2A2E30;
  background-color: #eff7ff;
  border-color: #000; }
  .btn-reddit:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-reddit:focus, .btn-reddit.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .btn-reddit.disabled, .btn-reddit:disabled {
    color: #2A2E30;
    background-color: #eff7ff;
    border-color: #000; }
  .btn-reddit:not(:disabled):not(.disabled):active, .btn-reddit:not(:disabled):not(.disabled).active,
  .show > .btn-reddit.dropdown-toggle {
    color: #2A2E30;
    background-color: #bcdeff;
    border-color: black; }
    .btn-reddit:not(:disabled):not(.disabled):active:focus, .btn-reddit:not(:disabled):not(.disabled).active:focus,
    .show > .btn-reddit.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.btn-soundcloud {
  background-color: #ff5500;
  color: #fff;
  background-color: #ff5500;
  border-color: #fff; }
  .btn-soundcloud:hover {
    color: #fff;
    background-color: #ff5500;
    border-color: #ff5500; }
  .btn-soundcloud:focus, .btn-soundcloud.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-soundcloud.disabled, .btn-soundcloud:disabled {
    color: #fff;
    background-color: #ff5500;
    border-color: #fff; }
  .btn-soundcloud:not(:disabled):not(.disabled):active, .btn-soundcloud:not(:disabled):not(.disabled).active,
  .show > .btn-soundcloud.dropdown-toggle {
    color: #fff;
    background-color: #cc4400;
    border-color: #dfdfdf; }
    .btn-soundcloud:not(:disabled):not(.disabled):active:focus, .btn-soundcloud:not(:disabled):not(.disabled).active:focus,
    .show > .btn-soundcloud.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-tumblr {
  background-color: #2c4762;
  color: #fff;
  background-color: #2c4762;
  border-color: #fff; }
  .btn-tumblr:hover {
    color: #fff;
    background-color: #2c4762;
    border-color: #2c4762; }
  .btn-tumblr:focus, .btn-tumblr.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-tumblr.disabled, .btn-tumblr:disabled {
    color: #fff;
    background-color: #2c4762;
    border-color: #fff; }
  .btn-tumblr:not(:disabled):not(.disabled):active, .btn-tumblr:not(:disabled):not(.disabled).active,
  .show > .btn-tumblr.dropdown-toggle {
    color: #fff;
    background-color: #1c2e3f;
    border-color: #dfdfdf; }
    .btn-tumblr:not(:disabled):not(.disabled):active:focus, .btn-tumblr:not(:disabled):not(.disabled).active:focus,
    .show > .btn-tumblr.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-twitter {
  background-color: #55acee;
  color: #2A2E30;
  background-color: #55acee;
  border-color: #fff; }
  .btn-twitter:hover {
    color: #2A2E30;
    background-color: #fff;
    border-color: #55acee; }
  .btn-twitter:focus, .btn-twitter.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-twitter.disabled, .btn-twitter:disabled {
    color: #2A2E30;
    background-color: #55acee;
    border-color: #fff; }
  .btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active,
  .show > .btn-twitter.dropdown-toggle {
    color: #fff;
    background-color: #2795e9;
    border-color: #dfdfdf; }
    .btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus,
    .show > .btn-twitter.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-vimeo {
  background-color: #1ab7ea;
  color: #fff;
  background-color: #1ab7ea;
  border-color: #fff; }
  .btn-vimeo:hover {
    color: #fff;
    background-color: #1ab7ea;
    border-color: #1ab7ea; }
  .btn-vimeo:focus, .btn-vimeo.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-vimeo.disabled, .btn-vimeo:disabled {
    color: #fff;
    background-color: #1ab7ea;
    border-color: #fff; }
  .btn-vimeo:not(:disabled):not(.disabled):active, .btn-vimeo:not(:disabled):not(.disabled).active,
  .show > .btn-vimeo.dropdown-toggle {
    color: #fff;
    background-color: #1295bf;
    border-color: #dfdfdf; }
    .btn-vimeo:not(:disabled):not(.disabled):active:focus, .btn-vimeo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-vimeo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-vk {
  background-color: #587ea3;
  color: #fff;
  background-color: #587ea3;
  border-color: #fff; }
  .btn-vk:hover {
    color: #fff;
    background-color: #587ea3;
    border-color: #587ea3; }
  .btn-vk:focus, .btn-vk.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-vk.disabled, .btn-vk:disabled {
    color: #fff;
    background-color: #587ea3;
    border-color: #fff; }
  .btn-vk:not(:disabled):not(.disabled):active, .btn-vk:not(:disabled):not(.disabled).active,
  .show > .btn-vk.dropdown-toggle {
    color: #fff;
    background-color: #466482;
    border-color: #dfdfdf; }
    .btn-vk:not(:disabled):not(.disabled):active:focus, .btn-vk:not(:disabled):not(.disabled).active:focus,
    .show > .btn-vk.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-yahoo {
  background-color: #720e9e;
  color: #fff;
  background-color: #720e9e;
  border-color: #fff; }
  .btn-yahoo:hover {
    color: #fff;
    background-color: #720e9e;
    border-color: #720e9e; }
  .btn-yahoo:focus, .btn-yahoo.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-yahoo.disabled, .btn-yahoo:disabled {
    color: #fff;
    background-color: #720e9e;
    border-color: #fff; }
  .btn-yahoo:not(:disabled):not(.disabled):active, .btn-yahoo:not(:disabled):not(.disabled).active,
  .show > .btn-yahoo.dropdown-toggle {
    color: #fff;
    background-color: #500a6f;
    border-color: #dfdfdf; }
    .btn-yahoo:not(:disabled):not(.disabled):active:focus, .btn-yahoo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-yahoo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-adn {
  border: 1px solid #d87a68 !important;
  color: #d87a68; }

.btn-outline-bitbucket {
  border: 1px solid #205081 !important;
  color: #205081; }

.btn-outline-dropbox {
  border: 1px solid #1087dd !important;
  color: #1087dd; }

.btn-outline-facebook {
  border: 1px solid #3b5998 !important;
  color: #3b5998; }

.btn-outline-flickr {
  border: 1px solid #ff0084 !important;
  color: #ff0084; }

.btn-outline-foursquare {
  border: 1px solid #f94877 !important;
  color: #f94877; }

.btn-outline-github {
  border: 1px solid #444444 !important;
  color: #444444; }

.btn-outline-google {
  border: 1px solid #dd4b39 !important;
  color: #dd4b39; }

.btn-outline-instagram {
  border: 1px solid #3f729b !important;
  color: #3f729b; }

.btn-outline-linkedin {
  border: 1px solid #007bb6 !important;
  color: #007bb6; }

.btn-outline-microsoft {
  border: 1px solid #2672ec !important;
  color: #2672ec; }

.btn-outline-odnoklassniki {
  border: 1px solid #f4731c !important;
  color: #f4731c; }

.btn-outline-openid {
  border: 1px solid #f7931e !important;
  color: #f7931e; }

.btn-outline-pinterest {
  border: 1px solid #cb2027 !important;
  color: #cb2027; }

.btn-outline-reddit {
  border: 1px solid #ff4500 !important;
  color: #ff4500; }

.btn-outline-soundcloud {
  border: 1px solid #ff5500 !important;
  color: #ff5500; }

.btn-outline-tumblr {
  border: 1px solid #2c4762 !important;
  color: #2c4762; }

.btn-outline-twitter {
  border: 1px solid #55acee !important;
  color: #55acee; }

.btn-outline-vimeo {
  border: 1px solid #1ab7ea !important;
  color: #1ab7ea; }

.btn-outline-vk {
  border: 1px solid #587ea3 !important;
  color: #587ea3; }

.btn-outline-yahoo {
  border: 1px solid #720e9e !important;
  color: #720e9e; }

.btn-outline-adn:hover {
  color: #ad412d;
  border: 1px solid #ad412d !important; }

.btn-outline-bitbucket:hover {
  color: #0c1d2f;
  border: 1px solid #0c1d2f !important; }

.btn-outline-dropbox:hover {
  color: #094d7e;
  border: 1px solid #094d7e !important; }

.btn-outline-facebook:hover {
  color: #1e2e4f;
  border: 1px solid #1e2e4f !important; }

.btn-outline-flickr:hover {
  color: #99004f;
  border: 1px solid #99004f !important; }

.btn-outline-foursquare:hover {
  color: #d4073d;
  border: 1px solid #d4073d !important; }

.btn-outline-github:hover {
  color: #111111;
  border: 1px solid #111111 !important; }

.btn-outline-google:hover {
  color: #96271a;
  border: 1px solid #96271a !important; }

.btn-outline-instagram:hover {
  color: #223d52;
  border: 1px solid #223d52 !important; }

.btn-outline-linkedin:hover {
  color: #003650;
  border: 1px solid #003650 !important; }

.btn-outline-microsoft:hover {
  color: #0e459e;
  border: 1px solid #0e459e !important; }

.btn-outline-odnoklassniki:hover {
  color: #a24608;
  border: 1px solid #a24608 !important; }

.btn-outline-openid:hover {
  color: #a95e06;
  border: 1px solid #a95e06 !important; }

.btn-outline-pinterest:hover {
  color: #731216;
  border: 1px solid #731216 !important; }

.btn-outline-reddit:hover {
  color: #992900;
  border: 1px solid #992900 !important; }

.btn-outline-soundcloud:hover {
  color: #993300;
  border: 1px solid #993300 !important; }

.btn-outline-tumblr:hover {
  color: #0c141c;
  border: 1px solid #0c141c !important; }

.btn-outline-twitter:hover {
  color: #147bc9;
  border: 1px solid #147bc9 !important; }

.btn-outline-vimeo:hover {
  color: #0d7091;
  border: 1px solid #0d7091 !important; }

.btn-outline-vk:hover {
  color: #344b61;
  border: 1px solid #344b61 !important; }

.btn-outline-yahoo:hover {
  color: #2e0640;
  border: 1px solid #2e0640 !important; }

.bg-adn {
  background-color: #d87a68; }

.bg-bitbucket {
  background-color: #205081; }

.bg-dropbox {
  background-color: #1087dd; }

.bg-facebook {
  background-color: #3b5998; }

.bg-flickr {
  background-color: #ff0084; }

.bg-foursquare {
  background-color: #f94877; }

.bg-github {
  background-color: #444444; }

.bg-google {
  background-color: #dd4b39; }

.bg-instagram {
  background-color: #3f729b; }

.bg-linkedin {
  background-color: #007bb6; }

.bg-microsoft {
  background-color: #2672ec; }

.bg-odnoklassniki {
  background-color: #f4731c; }

.bg-openid {
  background-color: #f7931e; }

.bg-pinterest {
  background-color: #cb2027; }

.bg-reddit {
  background-color: #ff4500; }

.bg-soundcloud {
  background-color: #ff5500; }

.bg-tumblr {
  background-color: #2c4762; }

.bg-twitter {
  background-color: #55acee; }

.bg-vimeo {
  background-color: #1ab7ea; }

.bg-vk {
  background-color: #587ea3; }

.bg-yahoo {
  background-color: #720e9e; }

/*=========================================================================================
	File Name: demo.scss
	Description: CSS used for demo purpose only. Remove this css from your project.
	----------------------------------------------------------------------------------------
	Item Name: Modern Admin - Clean Bootstrap 4 Dashboard HTML Template
	Version: 1.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.fonticon-container > .fonticon-wrap {
  float: left;
  width: 60px;
  height: 60px;
  line-height: 4.8rem;
  text-align: center;
  border-radius: 0.1875rem;
  margin-right: 1rem;
  margin-bottom: 1.5rem; }
  .fonticon-container > .fonticon-wrap > i {
    font-size: 2.28rem;
    transition: all .2s ease-in-out; }
  .fonticon-container > .fonticon-wrap.youtube {
    width: 100px; }

.fonticon-container:hover i {
  color: #666EE8;
  font-size: 2.9rem;
  transform: scale(1.3); }

.fonticon-container > .fonticon-classname, .fonticon-container > .fonticon-unit {
  display: block;
  font-size: 1.5rem;
  line-height: 1.2; }

.fonticon-container > .fonticon-unit {
  font-size: 1rem;
  font-style: italic; }

.scroll-example {
  padding: 0.5rem;
  position: relative;
  border: 2px solid #d1d5ea;
  overflow: auto; }
  .scroll-example .horz-scroll-content {
    width: 1200px; }

.browser {
  background: #e0e0e0;
  border: 4px solid #e0e0e0;
  width: 100%;
  height: 12rem;
  padding-top: 20px;
  margin: 0 0 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.browser iframe {
  border: 0;
  background: #fff;
  height: 100%;
  width: 100%; }

.loader-wrapper {
  height: 8em; }

.maintenance-icon {
  font-size: 4rem; }

.animationIcon {
  right: 30px;
  bottom: 10px; }

/*=========================================================================================
	File Name: customizer.scss
	Description: CSS used for demo purpose only. Remove this css from your project.
	----------------------------------------------------------------------------------------
	Item Name: Modern Admin - Clean Bootstrap 4 Dashboard HTML Template
	Version: 1.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.customizer {
  width: 400px;
  right: -400px;
  padding: 0;
  background-color: #FFF;
  z-index: 1051;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  backface-visibility: hidden;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); }
  .customizer.open {
    right: 0; }
  .customizer .customizer-content {
    position: relative;
    height: 100%; }
  .customizer a.customizer-toggle {
    background: #FFF;
    color: #666EE8;
    display: block;
    border-radius: 15px 0 0 15px; }
  .customizer a.customizer-close {
    color: #000; }
  .customizer .customizer-close {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 7px;
    width: auto;
    z-index: 10; }
  .customizer .customizer-toggle {
    position: absolute;
    top: 35%;
    width: 54px;
    height: 50px;
    left: -54px;
    text-align: center;
    line-height: 56px;
    cursor: pointer; }
  .customizer .color-options a {
    white-space: pre; }
  .customizer .customizer-sidebar-options .active {
    background-color: #666EE8;
    border-color: #666EE8;
    color: #FFF; }

.ps-container {
  -ms-touch-action: none;
  touch-action: none;
  overflow: hidden !important;
  -ms-overflow-style: none; }
  @supports (-ms-overflow-style: none) {
    .ps-container {
      overflow: auto !important; } }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps-container {
      overflow: auto !important; } }
  .ps-container.ps-active-x > .ps-scrollbar-x-rail,
  .ps-container.ps-active-y > .ps-scrollbar-y-rail {
    display: block;
    background-color: transparent; }
  .ps-container.ps-in-scrolling {
    pointer-events: none; }
    .ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: #eee;
      opacity: 0.9; }
      .ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: #999; }
    .ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: #eee;
      opacity: 0.9; }
      .ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: #999; }
  .ps-container > .ps-scrollbar-x-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 4px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    bottom: 3px;
    /* there must be 'bottom' for ps-scrollbar-x-rail */
    height: 8px; }
    .ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
      position: absolute;
      /* please don't change 'position' */
      background-color: #aaa;
      border-radius: 4px;
      transition: background-color .2s linear;
      bottom: 0;
      /* there must be 'bottom' for ps-scrollbar-x */
      height: 8px; }
  .ps-container > .ps-scrollbar-y-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 4px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    right: 3px;
    /* there must be 'right' for ps-scrollbar-y-rail */
    width: 8px; }
    .ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
      position: absolute;
      /* please don't change 'position' */
      background-color: #aaa;
      border-radius: 4px;
      transition: background-color .2s linear;
      right: 0;
      /* there must be 'right' for ps-scrollbar-y */
      width: 8px; }
  .ps-container:hover.ps-in-scrolling {
    pointer-events: none; }
    .ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: #eee;
      opacity: 0.9; }
      .ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: #999; }
    .ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: #eee;
      opacity: 0.9; }
      .ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: #999; }
  .ps-container:hover > .ps-scrollbar-x-rail,
  .ps-container:hover > .ps-scrollbar-y-rail {
    opacity: 0.6; }
  .ps-container:hover > .ps-scrollbar-x-rail:hover {
    background-color: #eee;
    opacity: 0.9; }
    .ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
      background-color: #999; }
  .ps-container:hover > .ps-scrollbar-y-rail:hover {
    background-color: #eee;
    opacity: 0.9; }
    .ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
      background-color: #999; }

.ps-theme-dark {
  -ms-touch-action: none;
  touch-action: none;
  overflow: hidden !important;
  -ms-overflow-style: none; }
  @supports (-ms-overflow-style: none) {
    .ps-theme-dark {
      overflow: auto !important; } }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps-theme-dark {
      overflow: auto !important; } }
  .ps-theme-dark.ps-active-x > .ps-scrollbar-x-rail,
  .ps-theme-dark.ps-active-y > .ps-scrollbar-y-rail {
    display: block;
    background-color: transperant; }
  .ps-theme-dark.ps-in-scrolling {
    pointer-events: none; }
    .ps-theme-dark.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: rgba(0, 0, 0, 0.1);
      opacity: 0.9; }
      .ps-theme-dark.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: rgba(0, 0, 0, 0.4); }
    .ps-theme-dark.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: rgba(0, 0, 0, 0.1);
      opacity: 0.9; }
      .ps-theme-dark.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: rgba(0, 0, 0, 0.4); }
  .ps-theme-dark > .ps-scrollbar-x-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 4px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    bottom: 3px;
    /* there must be 'bottom' for ps-scrollbar-x-rail */
    height: 8px; }
    .ps-theme-dark > .ps-scrollbar-x-rail > .ps-scrollbar-x {
      position: absolute;
      /* please don't change 'position' */
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      transition: background-color .2s linear;
      bottom: 0;
      /* there must be 'bottom' for ps-scrollbar-x */
      height: 8px; }
  .ps-theme-dark > .ps-scrollbar-y-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 4px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    right: 0;
    /* there must be 'right' for ps-scrollbar-y-rail */
    width: 6px; }
    .ps-theme-dark > .ps-scrollbar-y-rail > .ps-scrollbar-y {
      position: absolute;
      /* please don't change 'position' */
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      transition: background-color .2s linear;
      right: 0;
      /* there must be 'right' for ps-scrollbar-y */
      width: 6px; }
  .ps-theme-dark:hover.ps-in-scrolling {
    pointer-events: none; }
    .ps-theme-dark:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: rgba(0, 0, 0, 0.1);
      opacity: 0.9; }
      .ps-theme-dark:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: rgba(0, 0, 0, 0.4); }
    .ps-theme-dark:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: rgba(0, 0, 0, 0.1);
      opacity: 0.9; }
      .ps-theme-dark:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: rgba(0, 0, 0, 0.4); }
  .ps-theme-dark:hover > .ps-scrollbar-x-rail,
  .ps-theme-dark:hover > .ps-scrollbar-y-rail {
    opacity: 0.6; }
  .ps-theme-dark:hover > .ps-scrollbar-x-rail:hover {
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0.9; }
    .ps-theme-dark:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
      background-color: rgba(0, 0, 0, 0.4); }
  .ps-theme-dark:hover > .ps-scrollbar-y-rail:hover {
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0.9; }
    .ps-theme-dark:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
      background-color: rgba(0, 0, 0, 0.4); }

.ps-theme-light {
  -ms-touch-action: none;
  touch-action: none;
  overflow: hidden !important;
  -ms-overflow-style: none; }
  @supports (-ms-overflow-style: none) {
    .ps-theme-light {
      overflow: auto !important; } }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps-theme-light {
      overflow: auto !important; } }
  .ps-theme-light.ps-active-x > .ps-scrollbar-x-rail,
  .ps-theme-light.ps-active-y > .ps-scrollbar-y-rail {
    display: block;
    background-color: transperant; }
  .ps-theme-light.ps-in-scrolling {
    pointer-events: none; }
    .ps-theme-light.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: rgba(255, 255, 255, 0.2);
      opacity: 0.9; }
      .ps-theme-light.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: rgba(255, 255, 255, 0.8); }
    .ps-theme-light.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: rgba(255, 255, 255, 0.2);
      opacity: 0.9; }
      .ps-theme-light.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: rgba(255, 255, 255, 0.8); }
  .ps-theme-light > .ps-scrollbar-x-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 4px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    bottom: 3px;
    /* there must be 'bottom' for ps-scrollbar-x-rail */
    height: 8px; }
    .ps-theme-light > .ps-scrollbar-x-rail > .ps-scrollbar-x {
      position: absolute;
      /* please don't change 'position' */
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 4px;
      transition: background-color .2s linear;
      bottom: 0;
      /* there must be 'bottom' for ps-scrollbar-x */
      height: 8px; }
  .ps-theme-light > .ps-scrollbar-y-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 4px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    right: 0;
    /* there must be 'right' for ps-scrollbar-y-rail */
    width: 6px; }
    .ps-theme-light > .ps-scrollbar-y-rail > .ps-scrollbar-y {
      position: absolute;
      /* please don't change 'position' */
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 4px;
      transition: background-color .2s linear;
      right: 0;
      /* there must be 'right' for ps-scrollbar-y */
      width: 6px; }
  .ps-theme-light:hover.ps-in-scrolling {
    pointer-events: none; }
    .ps-theme-light:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: rgba(255, 255, 255, 0.2);
      opacity: 0.9; }
      .ps-theme-light:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: rgba(255, 255, 255, 0.8); }
    .ps-theme-light:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: rgba(255, 255, 255, 0.2);
      opacity: 0.9; }
      .ps-theme-light:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: rgba(255, 255, 255, 0.8); }
  .ps-theme-light:hover > .ps-scrollbar-x-rail,
  .ps-theme-light:hover > .ps-scrollbar-y-rail {
    opacity: 0.6; }
  .ps-theme-light:hover > .ps-scrollbar-x-rail:hover {
    background-color: rgba(255, 255, 255, 0.2);
    opacity: 0.9; }
    .ps-theme-light:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
      background-color: rgba(255, 255, 255, 0.8); }
  .ps-theme-light:hover > .ps-scrollbar-y-rail:hover {
    background-color: rgba(255, 255, 255, 0.2);
    opacity: 0.9; }
    .ps-theme-light:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
      background-color: rgba(255, 255, 255, 0.8); }

.menu-collapsed .ps-theme-dark {
  -ms-touch-action: none;
  touch-action: none;
  overflow: hidden !important;
  -ms-overflow-style: none; }
  @supports (-ms-overflow-style: none) {
    .menu-collapsed .ps-theme-dark {
      overflow: auto !important; } }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .menu-collapsed .ps-theme-dark {
      overflow: auto !important; } }
  .menu-collapsed .ps-theme-dark.ps-active-x > .ps-scrollbar-x-rail,
  .menu-collapsed .ps-theme-dark.ps-active-y > .ps-scrollbar-y-rail {
    display: block;
    background-color: transparent; }
  .menu-collapsed .ps-theme-dark.ps-in-scrolling {
    pointer-events: none; }
    .menu-collapsed .ps-theme-dark.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: #eee;
      opacity: 0.9; }
      .menu-collapsed .ps-theme-dark.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: #999; }
    .menu-collapsed .ps-theme-dark.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: #eee;
      opacity: 0.9; }
      .menu-collapsed .ps-theme-dark.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: #999; }
  .menu-collapsed .ps-theme-dark > .ps-scrollbar-x-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 4px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    bottom: 3px;
    /* there must be 'bottom' for ps-scrollbar-x-rail */
    height: 8px; }
    .menu-collapsed .ps-theme-dark > .ps-scrollbar-x-rail > .ps-scrollbar-x {
      position: absolute;
      /* please don't change 'position' */
      background-color: #55595c !important;
      border-radius: 4px;
      transition: background-color .2s linear;
      bottom: 0;
      /* there must be 'bottom' for ps-scrollbar-x */
      height: 8px; }
  .menu-collapsed .ps-theme-dark > .ps-scrollbar-y-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 4px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    right: 0;
    /* there must be 'right' for ps-scrollbar-y-rail */
    width: 6px; }
    .menu-collapsed .ps-theme-dark > .ps-scrollbar-y-rail > .ps-scrollbar-y {
      position: absolute;
      /* please don't change 'position' */
      background-color: #55595c !important;
      border-radius: 4px;
      transition: background-color .2s linear;
      right: 0;
      /* there must be 'right' for ps-scrollbar-y */
      width: 6px; }
  .menu-collapsed .ps-theme-dark:hover.ps-in-scrolling {
    pointer-events: none; }
    .menu-collapsed .ps-theme-dark:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: #eee;
      opacity: 0.9; }
      .menu-collapsed .ps-theme-dark:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: #999; }
    .menu-collapsed .ps-theme-dark:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: #eee;
      opacity: 0.9; }
      .menu-collapsed .ps-theme-dark:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: #999; }
  .menu-collapsed .ps-theme-dark:hover > .ps-scrollbar-x-rail,
  .menu-collapsed .ps-theme-dark:hover > .ps-scrollbar-y-rail {
    opacity: 0; }
  .menu-collapsed .ps-theme-dark:hover > .ps-scrollbar-x-rail:hover {
    background-color: #eee;
    opacity: 0.9; }
    .menu-collapsed .ps-theme-dark:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
      background-color: #999; }
  .menu-collapsed .ps-theme-dark:hover > .ps-scrollbar-y-rail:hover {
    background-color: #eee;
    opacity: 0.9; }
    .menu-collapsed .ps-theme-dark:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
      background-color: #999; }

.menu-collapsed ul.ps-theme-dark {
  -ms-touch-action: none;
  touch-action: none;
  overflow: hidden !important;
  -ms-overflow-style: none; }
  @supports (-ms-overflow-style: none) {
    .menu-collapsed ul.ps-theme-dark {
      overflow: auto !important; } }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .menu-collapsed ul.ps-theme-dark {
      overflow: auto !important; } }
  .menu-collapsed ul.ps-theme-dark.ps-active-x > .ps-scrollbar-x-rail,
  .menu-collapsed ul.ps-theme-dark.ps-active-y > .ps-scrollbar-y-rail {
    display: block;
    background-color: transperant; }
  .menu-collapsed ul.ps-theme-dark.ps-in-scrolling {
    pointer-events: none; }
    .menu-collapsed ul.ps-theme-dark.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: rgba(0, 0, 0, 0.1);
      opacity: 0.9; }
      .menu-collapsed ul.ps-theme-dark.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: rgba(0, 0, 0, 0.4); }
    .menu-collapsed ul.ps-theme-dark.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: rgba(0, 0, 0, 0.1);
      opacity: 0.9; }
      .menu-collapsed ul.ps-theme-dark.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: rgba(0, 0, 0, 0.4); }
  .menu-collapsed ul.ps-theme-dark > .ps-scrollbar-x-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 4px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    bottom: 3px;
    /* there must be 'bottom' for ps-scrollbar-x-rail */
    height: 8px; }
    .menu-collapsed ul.ps-theme-dark > .ps-scrollbar-x-rail > .ps-scrollbar-x {
      position: absolute;
      /* please don't change 'position' */
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      transition: background-color .2s linear;
      bottom: 0;
      /* there must be 'bottom' for ps-scrollbar-x */
      height: 8px; }
  .menu-collapsed ul.ps-theme-dark > .ps-scrollbar-y-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 4px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    right: 0;
    /* there must be 'right' for ps-scrollbar-y-rail */
    width: 6px; }
    .menu-collapsed ul.ps-theme-dark > .ps-scrollbar-y-rail > .ps-scrollbar-y {
      position: absolute;
      /* please don't change 'position' */
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      transition: background-color .2s linear;
      right: 0;
      /* there must be 'right' for ps-scrollbar-y */
      width: 6px; }
  .menu-collapsed ul.ps-theme-dark:hover.ps-in-scrolling {
    pointer-events: none; }
    .menu-collapsed ul.ps-theme-dark:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: rgba(0, 0, 0, 0.1);
      opacity: 0.9; }
      .menu-collapsed ul.ps-theme-dark:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: rgba(0, 0, 0, 0.4); }
    .menu-collapsed ul.ps-theme-dark:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: rgba(0, 0, 0, 0.1);
      opacity: 0.9; }
      .menu-collapsed ul.ps-theme-dark:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: rgba(0, 0, 0, 0.4); }
  .menu-collapsed ul.ps-theme-dark:hover > .ps-scrollbar-x-rail,
  .menu-collapsed ul.ps-theme-dark:hover > .ps-scrollbar-y-rail {
    opacity: 0.6; }
  .menu-collapsed ul.ps-theme-dark:hover > .ps-scrollbar-x-rail:hover {
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0.9; }
    .menu-collapsed ul.ps-theme-dark:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
      background-color: rgba(0, 0, 0, 0.4); }
  .menu-collapsed ul.ps-theme-dark:hover > .ps-scrollbar-y-rail:hover {
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0.9; }
    .menu-collapsed ul.ps-theme-dark:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
      background-color: rgba(0, 0, 0, 0.4); }

.vertical-menu-modern.menu-collapsed .ps-theme-dark {
  -ms-touch-action: none;
  touch-action: none;
  overflow: hidden !important;
  -ms-overflow-style: none; }
  @supports (-ms-overflow-style: none) {
    .vertical-menu-modern.menu-collapsed .ps-theme-dark {
      overflow: auto !important; } }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .vertical-menu-modern.menu-collapsed .ps-theme-dark {
      overflow: auto !important; } }
  .vertical-menu-modern.menu-collapsed .ps-theme-dark.ps-active-x > .ps-scrollbar-x-rail,
  .vertical-menu-modern.menu-collapsed .ps-theme-dark.ps-active-y > .ps-scrollbar-y-rail {
    display: block;
    background-color: transperant; }
  .vertical-menu-modern.menu-collapsed .ps-theme-dark.ps-in-scrolling {
    pointer-events: none; }
    .vertical-menu-modern.menu-collapsed .ps-theme-dark.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: rgba(0, 0, 0, 0.1);
      opacity: 0.9; }
      .vertical-menu-modern.menu-collapsed .ps-theme-dark.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: rgba(0, 0, 0, 0.4); }
    .vertical-menu-modern.menu-collapsed .ps-theme-dark.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: rgba(0, 0, 0, 0.1);
      opacity: 0.9; }
      .vertical-menu-modern.menu-collapsed .ps-theme-dark.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: rgba(0, 0, 0, 0.4); }
  .vertical-menu-modern.menu-collapsed .ps-theme-dark > .ps-scrollbar-x-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 4px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    bottom: 3px;
    /* there must be 'bottom' for ps-scrollbar-x-rail */
    height: 8px; }
    .vertical-menu-modern.menu-collapsed .ps-theme-dark > .ps-scrollbar-x-rail > .ps-scrollbar-x {
      position: absolute;
      /* please don't change 'position' */
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      transition: background-color .2s linear;
      bottom: 0;
      /* there must be 'bottom' for ps-scrollbar-x */
      height: 8px; }
  .vertical-menu-modern.menu-collapsed .ps-theme-dark > .ps-scrollbar-y-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 4px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    right: 0;
    /* there must be 'right' for ps-scrollbar-y-rail */
    width: 6px; }
    .vertical-menu-modern.menu-collapsed .ps-theme-dark > .ps-scrollbar-y-rail > .ps-scrollbar-y {
      position: absolute;
      /* please don't change 'position' */
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      transition: background-color .2s linear;
      right: 0;
      /* there must be 'right' for ps-scrollbar-y */
      width: 6px; }
  .vertical-menu-modern.menu-collapsed .ps-theme-dark:hover.ps-in-scrolling {
    pointer-events: none; }
    .vertical-menu-modern.menu-collapsed .ps-theme-dark:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: rgba(0, 0, 0, 0.1);
      opacity: 0.9; }
      .vertical-menu-modern.menu-collapsed .ps-theme-dark:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: rgba(0, 0, 0, 0.4); }
    .vertical-menu-modern.menu-collapsed .ps-theme-dark:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: rgba(0, 0, 0, 0.1);
      opacity: 0.9; }
      .vertical-menu-modern.menu-collapsed .ps-theme-dark:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: rgba(0, 0, 0, 0.4); }
  .vertical-menu-modern.menu-collapsed .ps-theme-dark:hover > .ps-scrollbar-x-rail,
  .vertical-menu-modern.menu-collapsed .ps-theme-dark:hover > .ps-scrollbar-y-rail {
    opacity: 0.6; }
  .vertical-menu-modern.menu-collapsed .ps-theme-dark:hover > .ps-scrollbar-x-rail:hover {
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0.9; }
    .vertical-menu-modern.menu-collapsed .ps-theme-dark:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
      background-color: rgba(0, 0, 0, 0.4); }
  .vertical-menu-modern.menu-collapsed .ps-theme-dark:hover > .ps-scrollbar-y-rail:hover {
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0.9; }
    .vertical-menu-modern.menu-collapsed .ps-theme-dark:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
      background-color: rgba(0, 0, 0, 0.4); }

.vertical-menu-modern.menu-collapsed ul.ps-theme-dark {
  -ms-touch-action: none;
  touch-action: none;
  overflow: hidden !important;
  -ms-overflow-style: none; }
  @supports (-ms-overflow-style: none) {
    .vertical-menu-modern.menu-collapsed ul.ps-theme-dark {
      overflow: auto !important; } }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .vertical-menu-modern.menu-collapsed ul.ps-theme-dark {
      overflow: auto !important; } }
  .vertical-menu-modern.menu-collapsed ul.ps-theme-dark.ps-active-x > .ps-scrollbar-x-rail,
  .vertical-menu-modern.menu-collapsed ul.ps-theme-dark.ps-active-y > .ps-scrollbar-y-rail {
    display: block;
    background-color: transperant; }
  .vertical-menu-modern.menu-collapsed ul.ps-theme-dark.ps-in-scrolling {
    pointer-events: none; }
    .vertical-menu-modern.menu-collapsed ul.ps-theme-dark.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: rgba(0, 0, 0, 0.1);
      opacity: 0.9; }
      .vertical-menu-modern.menu-collapsed ul.ps-theme-dark.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: rgba(0, 0, 0, 0.4); }
    .vertical-menu-modern.menu-collapsed ul.ps-theme-dark.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: rgba(0, 0, 0, 0.1);
      opacity: 0.9; }
      .vertical-menu-modern.menu-collapsed ul.ps-theme-dark.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: rgba(0, 0, 0, 0.4); }
  .vertical-menu-modern.menu-collapsed ul.ps-theme-dark > .ps-scrollbar-x-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 4px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    bottom: 3px;
    /* there must be 'bottom' for ps-scrollbar-x-rail */
    height: 8px; }
    .vertical-menu-modern.menu-collapsed ul.ps-theme-dark > .ps-scrollbar-x-rail > .ps-scrollbar-x {
      position: absolute;
      /* please don't change 'position' */
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      transition: background-color .2s linear;
      bottom: 0;
      /* there must be 'bottom' for ps-scrollbar-x */
      height: 8px; }
  .vertical-menu-modern.menu-collapsed ul.ps-theme-dark > .ps-scrollbar-y-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 4px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    right: 0;
    /* there must be 'right' for ps-scrollbar-y-rail */
    width: 6px; }
    .vertical-menu-modern.menu-collapsed ul.ps-theme-dark > .ps-scrollbar-y-rail > .ps-scrollbar-y {
      position: absolute;
      /* please don't change 'position' */
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      transition: background-color .2s linear;
      right: 0;
      /* there must be 'right' for ps-scrollbar-y */
      width: 6px; }
  .vertical-menu-modern.menu-collapsed ul.ps-theme-dark:hover.ps-in-scrolling {
    pointer-events: none; }
    .vertical-menu-modern.menu-collapsed ul.ps-theme-dark:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: rgba(0, 0, 0, 0.1);
      opacity: 0.9; }
      .vertical-menu-modern.menu-collapsed ul.ps-theme-dark:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: rgba(0, 0, 0, 0.4); }
    .vertical-menu-modern.menu-collapsed ul.ps-theme-dark:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: rgba(0, 0, 0, 0.1);
      opacity: 0.9; }
      .vertical-menu-modern.menu-collapsed ul.ps-theme-dark:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: rgba(0, 0, 0, 0.4); }
  .vertical-menu-modern.menu-collapsed ul.ps-theme-dark:hover > .ps-scrollbar-x-rail,
  .vertical-menu-modern.menu-collapsed ul.ps-theme-dark:hover > .ps-scrollbar-y-rail {
    opacity: 0.6; }
  .vertical-menu-modern.menu-collapsed ul.ps-theme-dark:hover > .ps-scrollbar-x-rail:hover {
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0.9; }
    .vertical-menu-modern.menu-collapsed ul.ps-theme-dark:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
      background-color: rgba(0, 0, 0, 0.4); }
  .vertical-menu-modern.menu-collapsed ul.ps-theme-dark:hover > .ps-scrollbar-y-rail:hover {
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0.9; }
    .vertical-menu-modern.menu-collapsed ul.ps-theme-dark:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
      background-color: rgba(0, 0, 0, 0.4); }

/* to make scrollbars always visible */
.always-visible.ps-container > .ps-scrollbar-x-rail,
.always-visible.ps-container > .ps-scrollbar-y-rail {
  opacity: 0.6; }

.large-margin.ps-container > .ps-scrollbar-x-rail {
  margin: 0 25%; }

.large-margin.ps-container > .ps-scrollbar-y-rail {
  margin: 100px 0; }

@media screen and (min-width: 0px) {
  head {
    font-family: "xs 0px"; }
  body:after {
    content: "xs - min-width: 0px"; } }

@media screen and (min-width: 544px) {
  head {
    font-family: "sm 544px"; }
  body:after {
    content: "sm - min-width: 544px"; } }

@media screen and (min-width: 768px) {
  head {
    font-family: "md 768px"; }
  body:after {
    content: "md - min-width: 768px"; } }

@media screen and (min-width: 992px) {
  head {
    font-family: "lg 992px"; }
  body:after {
    content: "lg - min-width: 992px"; } }

@media screen and (min-width: 1200px) {
  head {
    font-family: "xl 1200px"; }
  body:after {
    content: "xl - min-width: 1200px"; } }

head {
  clear: both; }
  head title {
    font-family: "xs 0px, sm 544px, md 768px, lg 992px, xl 1200px"; }

body:after {
  display: none; }

*[data-usn-if] {
  display: none; }

/* ------------------------------------------------------------------------------
*
*  # Editor CSS
*
*  Summernote, Code Mirror & ACE Editors
*
*
* ---------------------------------------------------------------------------- */
/* CKEditor Starts */
#cke_ckeditor-inline {
  max-width: 68%; }

/* CKEditor Ends */
/* Summernote Style Starts */
.note-editor.note-frame {
  border-radius: 0.3rem; }

.note-popover.popover {
  display: none; }

.note-toolbar.panel-heading {
  color: #333;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  padding: 0.25rem 1rem 0.55rem !important; }

.note-toolbar button.note-btn {
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem; }

.note-toolbar .dropdown-toggle::after {
  display: none; }

/* Summernote Style Ends */
.content {
  overflow: visible;
  position: relative;
  width: auto;
  margin-left: 0;
  min-height: auto;
  padding: inherit; }

/* TinyMCE */
.mce-tinymce-inline {
  max-width: 80% !important; }

.mce-container-body {
  overflow: auto; }

/* Code Mirror */
.CodeMirror {
  border-top: 1px solid black;
  border-bottom: 1px solid black; }
  .CodeMirror .CodeMirror-selected {
    background-color: skyblue !important; }
  .CodeMirror .CodeMirror-selectedtext {
    color: white; }
  .CodeMirror .styled-background {
    background-color: #ff7; }
  .CodeMirror .cm-matchhighlight {
    background-color: lightgreen; }
  .CodeMirror .CodeMirror-selection-highlight-scrollbar {
    background-color: green; }

#codemirror-tabs + .CodeMirror .cm-tab {
  /*background: url(../../app-assets/images/icons/img.png);*/
  background-position: right;
  background-repeat: no-repeat; }

iframe#preview {
  height: 300px;
  border: 1px solid black;
  width: 100%; }

/* Ace Editor */
.ace_editor {
  position: relative;
  overflow: hidden;
  height: 320px; }

/* Quill Editor */
.ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6, .ql-editor, .ql-toolbar {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem; }

.group-area {
  margin-bottom: 3rem; }

.block {
  display: block;
  width: 100%; }

select[multiple='multiple'] {
  overflow: hidden; }

/* Input with icon */
.has-icon-left .form-control {
  padding-left: 2.5rem; }

.form-control-position {
  position: absolute;
  top: 1px;
  right: 0;
  z-index: 2;
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 3.2rem;
  text-align: center; }

select.form-control:not([size]):not([multiple]),
input.form-control {
  /*--------------------------
        Sizing
        --------------------------*/ }
  select.form-control:not([size]):not([multiple]).input-xl,
  input.form-control.input-xl {
    height: 3.75rem;
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    line-height: 1.45;
    border-radius: 0.35rem; }
  select.form-control:not([size]):not([multiple]).form-group-xl .form-control,
  input.form-control.form-group-xl .form-control {
    height: 3.75rem;
    padding: 0.5rem 0.5rem;
    font-size: 1.5rem;
    line-height: 1.45;
    border-radius: 0.35rem; }
  select.form-control:not([size]):not([multiple]).form-group-xl .form-control-static,
  input.form-control.form-group-xl .form-control-static {
    height: 3.55rem;
    padding: 0.5rem 0.5rem;
    font-size: 1.5rem;
    line-height: 1.7; }
  select.form-control:not([size]):not([multiple]).input-lg,
  input.form-control.input-lg {
    height: 3rem;
    padding: 0.75rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1.45;
    border-radius: 0.35rem; }
  select.form-control:not([size]):not([multiple]).form-group-lg .form-control,
  input.form-control.form-group-lg .form-control {
    height: 3rem;
    padding: 1rem 1.25rem;
    font-size: 1.25rem;
    line-height: 1.25;
    border-radius: 0.35rem; }
  select.form-control:not([size]):not([multiple]).form-group-lg select.form-control,
  input.form-control.form-group-lg select.form-control {
    height: calc(3.5625rem + 2px);
    line-height: calc(3.5625rem + 2px); }
  select.form-control:not([size]):not([multiple]).form-group-lg textarea.form-control,
  select.form-control:not([size]):not([multiple]).form-group-lg select[multiple].form-control,
  input.form-control.form-group-lg textarea.form-control,
  input.form-control.form-group-lg select[multiple].form-control {
    height: auto; }
  select.form-control:not([size]):not([multiple]).form-group-lg .form-control-static,
  input.form-control.form-group-lg .form-control-static {
    height: calc(3.5625rem + 2px);
    min-height: 2.7rem;
    padding: 2rem 1.25rem;
    font-size: 1.25rem;
    line-height: 1.25; }
  select.form-control:not([size]):not([multiple]).input-sm,
  input.form-control.input-sm {
    height: 2rem;
    padding: 0.95rem 0.95rem;
    font-size: 0.975rem;
    line-height: 1.45;
    border-radius: 0.21rem; }
  select.form-control:not([size]):not([multiple]).form-group-sm .form-control,
  input.form-control.form-group-sm .form-control {
    /* font-size: $font-size-sm;
                line-height: $line-height-sm; */
    height: calc(1.875rem + 2px);
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.45;
    border-radius: 0.21rem; }
  select.form-control:not([size]):not([multiple]).form-group-sm .form-control-static,
  input.form-control.form-group-sm .form-control-static {
    font-size: 0.875rem;
    line-height: 1; }
  select.form-control:not([size]):not([multiple]).input-xs,
  input.form-control.input-xs {
    height: 1.525rem;
    padding: 0.1rem 0.525rem;
    font-size: 0.75rem;
    line-height: 1.45;
    border-radius: 0.12rem; }
  select.form-control:not([size]):not([multiple]).form-group-xs .form-control,
  input.form-control.form-group-xs .form-control {
    height: 1.525rem;
    padding: 0.2rem 0.275rem;
    font-size: 0.75rem;
    line-height: 1.45;
    border-radius: 0.12rem; }
  select.form-control:not([size]):not([multiple]).form-group-xs .form-control-static,
  input.form-control.form-group-xs .form-control-static {
    height: 1.525rem;
    padding: 0.2rem 0.275rem;
    font-size: 0.75rem;
    line-height: 1.5; }
  select.form-control:not([size]):not([multiple]).input-xl + .form-control-position,
  select.form-control:not([size]):not([multiple]).form-group-xl > .form-control-position,
  input.form-control.input-xl + .form-control-position,
  input.form-control.form-group-xl > .form-control-position {
    width: 3.55rem;
    height: 3.55rem;
    line-height: 3.55rem;
    top: 4px; }
  select.form-control:not([size]):not([multiple]).input-lg + .form-control-position,
  select.form-control:not([size]):not([multiple]).form-group-lg > .form-control-position,
  input.form-control.input-lg + .form-control-position,
  input.form-control.form-group-lg > .form-control-position {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    top: 4px; }
  select.form-control:not([size]):not([multiple]).input-sm + .form-control-position,
  select.form-control:not([size]):not([multiple]).form-group-sm > .form-control-position,
  input.form-control.input-sm + .form-control-position,
  input.form-control.form-group-sm > .form-control-position {
    width: calc(1.875rem + 2px);
    height: calc(1.875rem + 2px);
    line-height: calc(1.875rem + 2px);
    top: 2px; }
  select.form-control:not([size]):not([multiple]).input-xs + .form-control-position,
  select.form-control:not([size]):not([multiple]).form-group-xs > .form-control-position,
  input.form-control.input-xs + .form-control-position,
  input.form-control.form-group-xs > .form-control-position {
    width: 1.525rem;
    height: 1.525rem;
    line-height: 1.525rem;
    top: 0; }

/*---------------------------------
Input Icon
---------------------------------*/
.position-relative .form-control {
  padding-right: calc(2.75rem + 2px); }
  .position-relative .form-control.input-xl {
    padding-right: 3.55rem; }
  .position-relative .form-control.input-lg {
    padding-right: calc(3.5625rem + 2px); }
  .position-relative .form-control.input-sm {
    padding-right: calc(1.875rem + 2px); }
  .position-relative .form-control.input-xs {
    padding-right: 1.525rem; }

/* Input Icon left */
.has-icon-left .form-control {
  padding-right: 1rem;
  padding-left: calc(2.75rem + 2px); }
  .has-icon-left .form-control.input-xl {
    padding-right: 0.5rem;
    padding-left: 3.55rem; }
  .has-icon-left .form-control.input-lg {
    padding-right: 1.25rem;
    padding-left: calc(3.5625rem + 2px); }
  .has-icon-left .form-control.input-sm {
    padding-right: 0.75rem;
    padding-left: 1.7rem; }
  .has-icon-left .form-control.input-xs {
    padding-right: 0.275rem;
    padding-left: 1.525rem; }

.has-icon-left .form-control-position {
  right: auto;
  left: inherit; }

input[type="color"] {
  height: calc(2.75rem + 2px); }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  line-height: 1.45; }

.font-size-large {
  font-size: 1.25rem; }

.font-size-base {
  font-size: 1rem; }

.font-size-small {
  font-size: 0.875rem; }

.font-size-xsmall {
  font-size: 0.75rem; }

.form-group-style {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.07);
  padding-top: 7px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 4px;
  overflow: hidden; }
  .form-group-style label {
    margin: 0;
    text-transform: uppercase;
    font-weight: 700; }
  .form-group-style .form-control {
    border: none;
    padding-left: 0;
    margin-top: -4px;
    background: none; }
  .form-group-style.focus {
    background-color: #f0f0f0; }
    .form-group-style.focus label {
      opacity: 0.5; }
  .form-group-style label.filled {
    opacity: 0.5; }

.input-group-xs .form-control,
.input-group-xs .input-group-addon,
.input-group-xs .input-group-btn > button {
  padding: 0.175rem 0.5rem;
  font-size: 0.725rem;
  line-height: 1.677777;
  border-bottom-left-radius: 0.175rem;
  border-top-left-radius: 0.175rem; }

.input-group-xs .input-group-btn > button {
  border-radius: 0.175rem; }

/* .input-group-btn > button {
    &.dropdown-toggle::after{
        margin-top: -1.75rem;
    }
} */
.input-group-addon {
  padding: 0.75rem; }

.floating-label-form-group-with-focus label,
.floating-label-form-group-with-value label {
  color: #666EE8 !important;
  text-transform: uppercase;
  font-weight: 400; }

.select2-container--classic:focus,
.select2-container--default:focus {
  outline: none; }

.select2-container--classic .img-flag,
.select2-container--default .img-flag {
  height: 15px;
  width: 18px; }

.select2-container--classic .select2-selection--single,
.select2-container--default .select2-selection--single {
  height: 40px !important;
  padding: 5px;
  border-color: #D9D9D9 !important; }
  .select2-container--classic .select2-selection--single .select2-selection__rendered i,
  .select2-container--default .select2-selection--single .select2-selection__rendered i {
    margin-right: 0.5rem; }

.select2-container--classic.select2-container--open .select2-selection--single,
.select2-container--default.select2-container--open .select2-selection--single {
  border-color: #CCCCCC !important;
  outline: 0; }

.select2-container--classic.select2-container--focus,
.select2-container--default.select2-container--focus {
  outline: 0; }
  .select2-container--classic.select2-container--focus .select2-selection--multiple,
  .select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: #CCCCCC !important;
    outline: 0; }

.select2-container--classic .select2-selection--multiple,
.select2-container--default .select2-selection--multiple {
  min-height: 40px !important;
  padding: 5px 8px !important;
  border-color: #D9D9D9 !important; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice,
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    padding: 2px 6px !important;
    margin-top: 0px !important;
    background-color: #666EE8 !important;
    border-color: #3a44e1 !important;
    color: #FFFFFF;
    margin-right: 8px !important;
    margin-bottom: 2px; }
  .select2-container--classic .select2-selection--multiple .select2-selection__rendered,
  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    display: block !important;
    padding: 0 !important; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove,
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #FFFFFF !important;
    float: right;
    margin-left: 0.5rem; }
  .select2-container--classic .select2-selection--multiple i,
  .select2-container--default .select2-selection--multiple i {
    position: relative;
    top: 1px;
    margin-right: 0.5rem;
    padding-left: 1px; }
  .select2-container--classic .select2-selection--multiple[class*=bg-] .select2-selection__choice,
  .select2-container--default .select2-selection--multiple[class*=bg-] .select2-selection__choice {
    background-color: rgba(0, 0, 0, 0.15) !important;
    border-color: rgba(0, 0, 0, 0.2) !important; }

.select2-container--classic .select2-results__options .select2-results__option i,
.select2-container--default .select2-results__options .select2-results__option i {
  margin-right: 0.5rem; }

.select2-container--classic .select2-results__options .select2-results__option[aria-selected=true],
.select2-container--default .select2-results__options .select2-results__option[aria-selected=true] {
  background-color: #666EE8 !important;
  color: #FFFFFF !important; }

.select2-container--classic .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #E0E0E0 !important;
  color: #424242 !important; }

.select2-container--classic .select2-result-repository .select2-result-repository__avatar,
.select2-container--default .select2-result-repository .select2-result-repository__avatar {
  width: 22%;
  float: left; }
  .select2-container--classic .select2-result-repository .select2-result-repository__avatar img,
  .select2-container--default .select2-result-repository .select2-result-repository__avatar img {
    width: 75px; }

.select2-container--classic .select2-result-repository .select2-result-repository__meta,
.select2-container--default .select2-result-repository .select2-result-repository__meta {
  width: 78%;
  float: right; }
  .select2-container--classic .select2-result-repository .select2-result-repository__meta .select2-result-repository__title,
  .select2-container--default .select2-result-repository .select2-result-repository__meta .select2-result-repository__title {
    font-size: 1.35rem;
    font-weight: 700;
    text-transform: capitalize; }
  .select2-container--classic .select2-result-repository .select2-result-repository__meta .select2-result-repository__statistics div,
  .select2-container--default .select2-result-repository .select2-result-repository__meta .select2-result-repository__statistics div {
    display: inline;
    margin-right: 1rem; }

.select2-container--classic .select-lg,
.select2-container--default .select-lg {
  height: calc(3.5625rem + 2px)0.55 !important;
  font-size: 1.25rem;
  margin-bottom: 0 !important;
  padding: 0.75rem 0; }
  .select2-container--classic .select-lg.select2-selection--single .select2-selection__rendered,
  .select2-container--default .select-lg.select2-selection--single .select2-selection__rendered {
    padding-top: 0.1rem;
    padding-left: 1rem;
    padding-right: 5.5rem;
    line-height: 1; }
  .select2-container--classic .select-lg.select2-selection--single .select2-selection__arrow,
  .select2-container--default .select-lg.select2-selection--single .select2-selection__arrow {
    top: 0.75rem !important; }
  .select2-container--classic .select-lg.select2-selection--multiple .select2-selection__choice,
  .select2-container--default .select-lg.select2-selection--multiple .select2-selection__choice {
    padding: 0.2rem 0.75rem !important;
    font-size: 1.25rem; }
  .select2-container--classic .select-lg.select2-selection--multiple .select2-search--inline .select2-search__field,
  .select2-container--default .select-lg.select2-selection--multiple .select2-search--inline .select2-search__field {
    padding: 0;
    font-size: 1.25rem; }

.select2-container--classic .select-sm,
.select2-container--default .select-sm {
  height: calc(calc(1.875rem + 2px) + 0.5rem) !important;
  font-size: 0.875rem;
  padding: 0.35rem 0.2rem;
  margin-bottom: 0 !important; }
  .select2-container--classic .select-sm.select2-selection--single .select2-selection__rendered,
  .select2-container--default .select-sm.select2-selection--single .select2-selection__rendered {
    padding-right: 5.5rem; }
  .select2-container--classic .select-sm.select2-selection--single .select2-selection__arrow,
  .select2-container--default .select-sm.select2-selection--single .select2-selection__arrow {
    top: 0.5rem !important; }
  .select2-container--classic .select-sm.select2-selection--multiple,
  .select2-container--default .select-sm.select2-selection--multiple {
    min-height: calc(calc(1.875rem + 2px) + 0.5rem) !important;
    padding: 0.25rem 0.5rem !important; }
    .select2-container--classic .select-sm.select2-selection--multiple .select2-selection__choice,
    .select2-container--default .select-sm.select2-selection--multiple .select2-selection__choice {
      padding: 0 0.5rem !important;
      font-size: 0.875rem; }
    .select2-container--classic .select-sm.select2-selection--multiple .select2-search--inline .select2-search__field,
    .select2-container--default .select-sm.select2-selection--multiple .select2-search--inline .select2-search__field {
      padding: 0.35rem 0;
      margin-top: 0; }

.select2-container--classic .select-xs,
.select2-container--default .select-xs {
  height: calc(calc(1.875rem + 2px)) !important;
  padding: 0 0.2rem;
  font-size: 0.75rem;
  margin-bottom: 0 !important;
  line-height: 1.5; }
  .select2-container--classic .select-xs.select2-selection--single .select2-selection__rendered,
  .select2-container--default .select-xs.select2-selection--single .select2-selection__rendered {
    padding-left: 0.525rem;
    padding-right: 4.55rem; }
  .select2-container--classic .select-xs.select2-selection--single .select2-selection__arrow,
  .select2-container--default .select-xs.select2-selection--single .select2-selection__arrow {
    top: 0.175rem !important; }
  .select2-container--classic .select-xs.select2-selection--multiple,
  .select2-container--default .select-xs.select2-selection--multiple {
    min-height: calc(calc(1.875rem + 2px) + 0.2rem) !important;
    padding: 4px 6px !important; }
    .select2-container--classic .select-xs.select2-selection--multiple .select2-selection__choice,
    .select2-container--default .select-xs.select2-selection--multiple .select2-selection__choice {
      padding: 0 0.4rem !important;
      font-size: 0.75rem; }
    .select2-container--classic .select-xs.select2-selection--multiple .select2-search--inline .select2-search__field,
    .select2-container--default .select-xs.select2-selection--multiple .select2-search--inline .select2-search__field {
      padding: 0;
      font-size: 0.75rem;
      margin-top: 0; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 6px !important;
  right: 6px !important; }

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  top: 1px !important;
  right: 2px !important;
  height: 96% !important; }

table.dataTable {
  border-spacing: 0px; }

.content-wrapper table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before, .content-wrapper table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
  background-color: #666EE8;
  border-radius: 0;
  top: 10px;
  left: 10px;
  box-shadow: 0 0 2px #444; }

.content-wrapper table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before, .content-wrapper table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  background-color: #FF4961; }

@media (max-width: 991.98px) {
  .dataTables_wrapper table {
    display: block;
    width: 100%;
    min-height: .01%;
    overflow-x: auto; } }

.DTFC_LeftBodyLiner {
  top: -1px !important; }

div.dataTables_wrapper {
  width: 100%;
  margin: 0 auto; }
  div.dataTables_wrapper div.dataTables_filter label {
    margin-top: 1rem; }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 10px 0; }

td.highlight {
  font-weight: bold;
  color: #666EE8;
  background-color: whitesmoke !important; }

tr.group {
  background-color: #ededed !important; }
  tr.group:hover {
    background-color: #ededed !important; }

th {
  white-space: nowrap; }

.toolbar {
  float: left; }

.no-style thead > tr > th, .no-style tfoot > tr > th {
  border-bottom: 1px solid #e3ebf3; }

table.dataTable.compact tbody td {
  padding: 4px; }

.table-striped tbody tr.odd.selected, .table-striped tbody tr.even.selected {
  background-color: #666EE8;
  color: #fff; }

table.dataTable tbody > tr > .selected {
  background-color: #666EE8; }

a.dt-button.red {
  color: red; }

a.dt-button.orange {
  color: orange; }

a.dt-button.green {
  color: green; }

.dataex-css-row tr {
  height: 55px; }

.dataex-key-customstyling td.focus {
  outline: 1px solid #ac1212;
  outline-offset: -3px;
  background-color: #e3ebf3 !important; }

.dt-events-log {
  border: 1px solid #888;
  background: #f7f7f7;
  padding: 1em;
  margin-bottom: 1em; }

.dataTables_wrapper tfoot input {
  width: 100%;
  box-sizing: border-box; }

td.details-control {
  /*background: url("../images/datatables/resources/details_open.png") no-repeat center center;*/
  cursor: pointer; }

tr.shown td.details-control {
  /*background: url("../images/datatables/resources/details_close.png") no-repeat center center;*/
}

.DTFC_LeftBodyLiner, .DTFC_RightBodyLiner {
  background: white; }

.datepaginator .pagination li, .datepaginator-sm .pagination li, .datepaginator-lg .pagination li {
  display: inline-block; }
  .datepaginator .pagination li a, .datepaginator-sm .pagination li a, .datepaginator-lg .pagination li a {
    position: relative;
    float: left;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.6;
    color: #1E9FF2;
    text-decoration: none;
    background-color: #fff;
    border: none; }
    .datepaginator .pagination li a.dp-off, .datepaginator-sm .pagination li a.dp-off, .datepaginator-lg .pagination li a.dp-off {
      background-color: #f5f5f5 !important; }
    .datepaginator .pagination li a.dp-today, .datepaginator-sm .pagination li a.dp-today, .datepaginator-lg .pagination li a.dp-today {
      background-color: #1E9FF2 !important; }
    .datepaginator .pagination li a.dp-selected, .datepaginator-sm .pagination li a.dp-selected, .datepaginator-lg .pagination li a.dp-selected {
      background-color: #1E9FF2 !important; }
    .datepaginator .pagination li a .dp-divider, .datepaginator-sm .pagination li a .dp-divider, .datepaginator-lg .pagination li a .dp-divider {
      border-left: 2px solid #ddd !important; }
    .datepaginator .pagination li a #dp-calendar, .datepaginator-sm .pagination li a #dp-calendar, .datepaginator-lg .pagination li a #dp-calendar {
      padding: 0px 5px 0 0 !important;
      margin-right: 3px;
      position: absolute;
      right: -5px;
      top: 0px;
      font-size: 1.4rem; }

.pagination.pagination-separate li:first-child a {
  margin-left: 0; }

.pagination.pagination-separate li a {
  margin-left: 3px; }

.pagination.pagination-separate.pagination-sm li.active a {
  padding: 0.5rem 0.7rem; }

.pagination li a.no-border {
  padding: 0.5rem 1rem; }

.pagination.pagination-round.pagination-flat li.active a {
  border-width: 1px;
  border-radius: 2rem;
  padding: 0.5rem 1rem; }

.pagination.pagination-round.pagination-flat.pagination-sm li.active a {
  padding: 0.5rem 0.7rem; }

.pagination.pagination-round li a.page-link:hover.no-border {
  border-width: 1px;
  border-radius: 2rem; }

.pagination.pagination-round li:first-child a {
  border-top-left-radius: 2rem !important;
  border-bottom-left-radius: 2rem !important;
  padding-left: 1.2rem; }

.pagination.pagination-round li:last-child a {
  border-top-right-radius: 2rem !important;
  border-bottom-right-radius: 2rem !important;
  padding-right: 1.2rem; }

.pagination.pagination-round li.active a.no-border, .pagination.pagination-round li.active a.no-border:hover {
  border-width: 1px;
  border-radius: 2rem;
  padding: 0.5rem 1rem; }

.pagination.pagination-curved li a {
  border-radius: 0.25rem; }

.pagination.pagination-curved.pagination-sm li.active a {
  padding: 0.5rem 0.7rem; }

.pagination.pagination-flat li a {
  border: 0;
  border-radius: 0.25rem; }

.pagination.pagination-flat li.active a {
  border-width: 1px;
  border-radius: 3px;
  padding: 0.5rem 1rem; }

.pagination.pagination-flat.pagination-sm li.active a {
  padding: 0.5rem 0.7rem; }
