@import url(https://fonts.googleapis.com/css?family=Lato:700);

// -- vars
$bg-color: #34495e;
$default-size: 22px;
$label-font-size: $default-size / 4;
$label-font-size-redo: $default-size * 4;

// -- mixins
@mixin size($width, $height) {
  height: $height;
  width: $width;
}

@mixin draw-progress($progress, $color) {
  .pie {
    .half-circle {
      border-color: $color;
    }

    .left-side {
      transform: rotate($progress * 3.6deg);
    }

    @if $progress <= 50 {
      .right-side {
        display: none;
      }
    } @else {
      clip: rect(auto, auto, auto, auto);

      .right-side {
        transform: rotate(180deg);
      }
    }
  }
}

@mixin draw-progress--solid($progress, $color, $bg-color) {
  background: linear-gradient(to right, $color 50%, $bg-color 50%);
  
  &:before {
    @if $progress == 0 {
      background: $bg-color;
      transform: rotate((100 - (-40 - $progress)) / 100 * 360deg * -1);
    } @else if $progress == 25{
        background: $color;
        transform: rotate((100 - (50 - $progress)) / 100 * 360deg * -1);
    } @else if $progress == 50{
        background: $color;
        transform: rotate((100 - (100 - $progress)) / 100 * 360deg * -1);
    }@else if $progress == 75{
        background: $bg-color;
        transform: rotate((100 - (100 - $progress)) / 100 * 360deg * -1);
    } @else {
      background: $bg-color;
      transform: rotate((100 - (100 - $progress)) / 100 * 360deg * -1);
    }
  }
}

// -- selectors
*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  background: #ecf0f1;
  color: #444;
  font-family: 'Lato', Tahoma, Geneva, sans-serif;
  font-size: 16px;
  padding: 10px;
}

.set-size {
  font-size: 10em;
}

.charts-container {
  &:after {
    clear: both;
    content: '';
    display: table;
  }
}

.pie-wrapper {
  @include size($default-size, $default-size);
  float: left;
  margin: 10px;
  position: relative;
  
  &:nth-child(3n + 1) {
    clear: both;
  }

  .label {
    background: $bg-color;
    border-radius: 50%;
    bottom: $label-font-size-redo / 10;
    color: #ecf0f1;
    cursor: default;
    display: block;
    font-size: $label-font-size;
    left: $label-font-size-redo / 10;
    line-height: $label-font-size-redo * .70;
    position: absolute;
    right: $label-font-size-redo / 10;
    text-align: center;
    top: $label-font-size-redo / 10;

    .smaller {
      color: #bdc3c7;
      font-size: .45em;
      padding-bottom: 20px;
      vertical-align: super;
    }
  }

  .shadow {
    @include size(100%, 100%);
    border: $default-size / 10 solid #bdc3c7;
    border-radius: 50%;
  }

  &.style-2 {
    .label {
      background: none;
      color: #7f8c8d;

      .smaller {
        color: #bdc3c7;
      }
    }
  }
}

.pie-wrapper--solid {
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid;

  &:before {
    border-radius: 0 100% 100% 0 / 50%;
    content: '';
    display: block;
    height: 100%;
    margin-left: 50%;
    transform-origin: left;
  }
  
  .label {
    background: transparent;
  }

  &.progress-0 {
    @include draw-progress--solid(0, white, white);
    border-color: #CCCCCC;
  }

  &.progress-25 {
    @include draw-progress--solid(25, white, #FF9F00);
    border-color: #CCCCCC;
  }
  
  &.progress-50 {
    @include draw-progress--solid(50, white, #fbff00);
    border-color: #CCCCCC;
  }
  
  &.progress-75 {
    @include draw-progress--solid(75, white, #bbff00);
    border-color: #CCCCCC;
  }
  
  &.progress-100 {
    @include draw-progress--solid(100, #00C87F, #00C87F);
    border-color: #00C87F;
    &.confirmed{
      @include draw-progress--solid(100, #bbff00, #bbff00);
      border-color: #bbff00;
    }
  }
}



.status-lead-container {
    display: flex;
    align-items: baseline;
}

.status-lead-container__checkbox {
    min-width: 22px;
    min-height: 22px;
    margin-right: 10px !important;
    cursor: pointer;
}

.status-lead-container__checkbox input {
    opacity: 0;
    cursor: pointer;
}

.leads-content-row-header{
    display: flex;
    justify-content: space-between;
    .content-header-left{
        max-width: 220px;
    }
    .content-header-right{
        width: 100% !important;
        flex: 0 0 100% !important; 
        max-width: calc(100% - 250px) !important; 
    }
    .leads-content-header{
        width: 100%;
        position: relative;
        .quiz__button--actions{
            width: 137px;
            height: 38px;
        }
        .dropdown{
            >div{
                min-width: 100%;
                left: 0 !important;
            }
        }
        .drop-status{
            position: absolute;
            left: 0;
            >button{
                height: 38px;
                padding: 0;
                background-color: white !important;
                border: 2px solid #00ADFF;
                color: #00ADFF !important;
                border-color: #00ADFF !important;
                &:hover{
                    color: black !important;
                }
            }
        }
        .drop-button{
            margin-right: 20px;
            >button{
                background-color: white !important;
                border: none;
                height: 38px;
                color: #7A7A7A !important;
                font-size: 15px;
                padding: 0;
                &::after{
                    color: black !important;
                }
                &:hover{
                    color: black !important;
                    background-color: white;
                }
            }
        }
    }
}

.leads-page{
    position: relative;
    .empty-leads{
      height: 50vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      h1{
        margin-bottom: 30px;
        color: #747474;
        font-weight: 300;
        font-size: 28px;
      }
    }
    .search-bar{
        position: absolute;
        top: -68px;
        input{
            padding-left: 45px;
            border-radius: 0;
        }
        .search-icon{
            font-size: 18px;
            cursor: pointer;
            position: absolute;
            left: 13px;
            font-weight: 600 !important;
            color: #C3C3C3;
            top: 8px;
        }
    }
    .leads-table{
        margin: 50px 0 !important;
        .table-header{
            display: table;
            width: 100%;
            border-bottom: 1px solid #DCDCDC;
            margin-bottom: 20px;
            padding: 20px 17px;
            >p, div{
                display: table-cell;
                color: #000000;
                font-weight: 500;
                font-size: 15px;
                &.checkbox-container{
                    width: 5%;
                }
            }
            >p{
                &.date{
                    width: 20%;
                }
                &.prog{
                    width: 15%;
                    padding-left: 18px;
                }
                &.status{
                    width: 20%;
                    padding-left: 20px;
                }
                &.contact{
                    width: 33%;
                    padding-left: 10px;
                }
                &.action{
                    width: 12%;
                }
            }
        }
        .card{
            box-shadow: none !important;
            padding: 24px 0 24px 16px;
            margin: 0;
            &:nth-child(odd) {
                background: #FAFAFA;
            }
            .card-content{
                .table-responsive{
                    >table{
                        margin: 0;
                        tr{
                            display: flex;
                            align-items: center;
                            td{
                                display: flex;
                                padding: 0;
                                border: none;
                                .checkbox-container {
                                  .container {
                                    display: flex;
                                    margin-bottom: 0;
                                    flex: 0 0 20px;
                                    height: 20px;
                                  }
                                }
                                &.progress-percent{
                                    display: flex;
                                    align-items: center;
                                    position: relative;
                                    &:hover{
                                      span{
                                        display: block;
                                      }
                                    }
                                  span{
                                    position: absolute;
                                    left: 0;
                                    top: 45px;
                                    z-index: 9999;
                                    color: black;
                                    padding: 10px 15px;
                                    box-shadow: 0 0 4px 0px silver;
                                    border-radius: 5px;
                                    display: none;
                                  }
                                }
                                &.status{
                                    >div{
                                        margin: 0 !important;
                                        >button{
                                            margin: 0 !important;
                                            border: none !important;
                                            background-color: transparent !important;
                                        }
                                    }
                                }
                                &.contact {
                                  display: block;
                                }
                                .contacts{
                                    display: flex;
                                    flex-direction: column;
                                    >span{
                                        margin-bottom: 10px;
                                        color: black;
                                        &.quiz-title{
                                          margin-bottom: 15px;
                                          font-size: 16px;
                                          font-weight: 500;
                                          color: #8b8b8b;
                                        }
                                        &:last-child{
                                          margin-bottom: 0;
                                        }
                                    }
                                }
                                .lead-action{
                                    a{
                                        background-color: white !important;
                                        border-radius: 100%;
                                        font-size: 16px;
                                        padding: 8px;
                                        margin-right: 13px;
                                        box-shadow: none;
                                        &:first-child{
                                            border: 2px solid #00CF8A !important;
                                            color:  #00C87F !important;
                                            &:hover{
                                              border: 2px solid #00CF8A  !important;
                                              color:  #ffffff !important;
                                              background-color: #00CF8A  !important;
                                            }
                                        }
                                        &:last-child{
                                            border: 2px solid black !important;
                                            color:  black !important;
                                            &:hover{
                                              border: 2px solid #000000 !important;
                                              color:  #ffffff !important;
                                              background-color: #000000 !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}

.modal-dialog{
  .modal-elems >div:first-child{
    margin-right: 25px;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: white; 
    }
    &::-webkit-scrollbar-thumb {
      background: #E3E3E3; 
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #E3E3E3; 
    }
  }
}

.quiz-filter {
  margin-top: 15px;

  & > span {
    display: inline-block;
    cursor: pointer;
    color: #1E9FF2;
  }
}