.modal-content-container {
  min-height: 70px;
}
.modal-content__telegram-consultant,  .modal-content__pause-waiting, .modal-content__show-offline {
  display: flex;
  align-items: center;

  &-title {
    margin: 0;
    margin-left: 10px;
  }
}

.modal-content__show-offline-title, .modal-content__pause-waiting-title, .modal-content__telegram-consultant-title {
  text-align: start;
}

.modal-content__enable-mode ul {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  list-style: none;

  li {
    line-height: 1.5;
  }
}

.modal-content__enable-mode p, .modal-content__pause-waiting-container p, .modal-content__show-offline-container p {
  margin-top: 15px;
  text-align: start;

  span {
    font-weight: bold;
    color: black;
  }
}
