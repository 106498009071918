#toast-container > div {
  opacity: 0.9;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
  filter: alpha(opacity=90); }

#toast-container > div:hover {
  -webkit-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75); }

.toast {
  background-color: #666EE8; }

.toast-success {
  background-color: #28D094; }

.toast-error {
  background-color: #FF4961; }

.toast-info {
  background-color: #1E9FF2; }

.toast-warning {
  background-color: #FF9149; }
