@font-face {
  font-family: 'YSText';
  src: url('./YandexSansText-Regular.eot');
  src: url('./YandexSansText-Regular.eot?#iefix') format('embedded-opentype'),
    url('./YandexSansText-Regular.woff2') format('woff2'),
    url('./YandexSansText-Regular.woff') format('woff'),
    url('./YandexSansText-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'YSText';
  src: url('./YandexSansText-Medium.eot');
  src: url('./YandexSansText-Medium.eot?#iefix') format('embedded-opentype'),
    url('./YandexSansText-Medium.woff2') format('woff2'),
    url('./YandexSansText-Medium.woff') format('woff'),
    url('./YandexSansText-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}