@import url('./assets/line-awesome/css/line-awesome.min.css');
@import url('./assets/css/vendors.css');
@import url('./assets/css/app.css');
@import url('./assets/css/style.css');
@import url('./assets/css/core/menu/menu-types/vertical-content-menu.css');
@import url('./assets/css/style.css');
@import url('./assets/css/wizard.css');
@import url('./assets/css/switchery.min.css');
@import url('./assets/css/switch.css');
@import url('./assets/css/s-alert-default.css');
@import url('./assets/css/toastr.css');
@import url('./assets/css/checkboxes-radios.css');
@import url('./assets/css/icheck/square/_all.css');
@import url('./assets/css/icheck/flat/_all.css');
@import url('./assets/css/fontawesome-all.css');
@import url('./assets/css/fa-brands.css');
@import url('./assets/css/bootstrap-treeview.min.css');
@import url('./assets/css/loaders.css');
@import url('./assets/css/timeline.css');
@import url('./assets/fonts/simple-line-icons/style.min.css');
@import url('./assets/css/core/colors/palette-callout.min.css');

/*@import url('./assets/line-awesome/css/line-awesome-font-awesome.min.css');*/
@font-face {
    font-family: Ubuntu;
    src: url('./assets/fonts/Ubuntu/Ubuntu-Medium.ttf');
}
