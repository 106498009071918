@charset "UTF-8";
.partner-page > .row .card {
  box-shadow: none !important; }
  .partner-page > .row .card .card-body {
    padding-top: 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #E2E2E2; }
    .partner-page > .row .card .card-body .col-lg-3 {
      display: flex !important;
      flex-direction: column-reverse !important; }
      .partner-page > .row .card .card-body .col-lg-3 .float-left p {
        margin-bottom: 11px !important; }
      .partner-page > .row .card .card-body .col-lg-3 .card-title {
        text-transform: uppercase;
        color: #000000;
        font-size: 17px; }
      .partner-page > .row .card .card-body .col-lg-3 .card-count {
        font-size: 28px; }

.partner-page .partner-content {
  display: flex;
  justify-content: space-between; }
  .partner-page .partner-content > div {
    display: flex;
    flex-direction: column; }
  .partner-page .partner-content .partner-info {
    width: 45%; }
    .partner-page .partner-content .partner-info .card-info .card-header {
      padding-bottom: 0;
      text-transform: uppercase; }
    .partner-page .partner-content .partner-info .card-info .card-body {
      padding-top: 10px;
      display: flex;
      align-items: center; }
      .partner-page .partner-content .partner-info .card-info .card-body i {
        cursor: pointer;
        font-size: 20px; }
      .partner-page .partner-content .partner-info .card-info .card-body .btn-create {
        padding: 8px 30px; }
      .partner-page .partner-content .partner-info .card-info .card-body fieldset {
        margin-right: 20px;
        margin-bottom: 0; }
        .partner-page .partner-content .partner-info .card-info .card-body fieldset.partner-link {
          width: 100%;
          min-width: 400px;
          max-width: 600px; }
        .partner-page .partner-content .partner-info .card-info .card-body fieldset.partner-payment {
          max-width: 100px;
          position: relative; }
          .partner-page .partner-content .partner-info .card-info .card-body fieldset.partner-payment::after {
            content: '₽';
            position: absolute;
            right: 10px;
            top: 11px;
            color: black;
            font-weight: normal;
            font-size: 16px; }
        .partner-page .partner-content .partner-info .card-info .card-body fieldset input {
          border: none !important;
          background: #F8F8F8;
          border-radius: 0;
          color: black;
          font-size: 16px;
          font-weight: normal; }
  .partner-page .partner-content .partner-about {
    width: 35%; }
    .partner-page .partner-content .partner-about .card-body .card-title {
      position: relative;
      margin-bottom: 18px; }
      .partner-page .partner-content .partner-about .card-body .card-title h4 {
        color: black;
        font-size: 18px; }
      .partner-page .partner-content .partner-about .card-body .card-title i {
        position: absolute;
        left: -40px;
        top: 0;
        color: #00C87F;
        font-size: 20px;
        font-weight: bold; }
    .partner-page .partner-content .partner-about .card-body .card-text {
      font-size: 16px;
      color: #6E6E6E;
      font-weight: 400; }

.txt-dark {
  color: #9B9B9B !important; }

.txt-green {
  color: #00C87F !important; }

.txt-black {
  color: #000000 !important; }

.txt-pink {
  color: #E0186C !important; }
