.quiz {
    width: 100%;
    background-color: #fff;
    margin-bottom: 30px;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.0580276);
    &__toolbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 10px;

        &-actions {
            display: inline-flex;
            flex-direction: row;
        }

        &-action {
            display: inline-flex;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__info {
        width: 80%;

        &-title {
            display: flex;
            align-items: center;
            margin-bottom: 13px;

            h2 {
                display: inline-block;
                margin: 0 15px;
                font-size: 18px;

                a {
                    margin-left: 15px;
                }
            }

            svg {
                min-width: 15px;
            }
        }
    }

    &__stats {
        max-width: 200px;
        min-width: 160px;
        padding-left: 15px;
        border-left: 1px solid #AFAFAF;

        &-item {
            color: black;
            display: flex;
            justify-content: space-between;
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 6px;
            background: rgba(196, 196, 196, 0.25);
            border-radius: 6px;
            padding: 5px;

            svg {
                margin-right: 12px;
            }
        }
    }

    &__link {
        margin-bottom: 8px;

        a {
            margin: 0 7px;
        }
    }

    &__button {
        display: flex;
        align-items: center;
        border-radius: 7px;
        height: 30px;
        line-height: 10px;
        border: none;
        color: white;
        cursor: pointer;
        background: rgba(255, 255, 255, 0);
        font-weight: 500;
        font-size: 14px;
        &:hover {
            color: #000000;
        }

        svg {
            margin-right: 8px;
        }

        &-hover {
            &--blue {
                background-color: #afafaf;

                &:hover {
                    background-color: #476fd3;
                }
            }

            &--violet {
                background-color: #afafaf;

                &:hover {
                    background-color: #5f40a0;
                }
            }

            &--red {
                background-color: #afafaf;

                &:hover {
                    background-color: #BC0B0B;
                }
            }
        }

        &--telegram {
            background-color: #BC0B0B;
        }

        &--published {
            color: #00B77A;
        }

        &--draft {
            color: #E0186C;
        }

        &--actions {
            background-color: white !important;
            border: 2px solid #000000 !important;
            border-radius: 18px !important;
            padding: 4px 17px !important;
            margin-right: 0 !important;
            color: black !important;
        }

        &--blocked, &--archived {
            background-color: #AFAFAF;
            padding: 5px 10px;
        }

        &--blocked {
            color: #8a0000;
            background: #f5f5f5ad;
            padding-left: 0;
            &:hover{
                color: #8a0000;
                cursor: unset;
            }
        }
    }
    .quiz-body{
        display: flex;
        justify-content: space-between;
        padding: 17px;
        .quiz-content{
            display: flex;
            .quiz-img{
                margin-right: 25px;
            }
            .quiz-title{
                margin-top: 5px;
                p{
                    color: #7E7E7E;
                    font-weight: normal;
                    font-size: 17px;
                    padding-right: 5px;
                    display: flex !important;
                    margin-bottom: 0;
                    &:hover{
                        color:  #0089c9 !important;
                        cursor: pointer;
                    }
                }
                .quiz-edit{
                    cursor: pointer;
                    &:hover{
                        color: #000000;
                        h4{
                            color: black;
                        }
                    }
                    h4{
                        display: contents;
                        margin-right: 10px;
                    }
                    i{
                        margin-left: 10px;
                        transform: translateY(-4px);
                    }
                }
                .quiz-domen{
                    display: flex;
                    margin-top: 10px;
                    >span{
                        display: flex;
                    }
                    button{
                        cursor: pointer;
                        width: unset !important;
                        &:hover{
                            background-color: white;
                            span{
                                color:  #0089c9 !important;
                            }
                        }
                        span{
                            padding: 0 2px !important;
                            color: #00ADFF;
                            font-size: 17px;
                        }
                        padding: 0 !important;
                    }
                }
            }
        }
        .dropdown{
            margin-top: 5px;
            margin-left: 10px;
            .quiz__toolbar-action {
                height: 37px;
                &:hover{
                    background-color: black !important;
                    color: white !important;
                }
                &.continue{
                    border-color: #E0186C !important;
                    color: #E0186C !important;
                    min-width: 220px;
                    &:hover{
                        background-color: #E0186C !important;
                        color: white !important;
                    }
                }
            }
        }
    }
    .quiz-footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        background: #f5f5f5ad;
        padding: 0 30px;
        .quiz-status{
            display: flex !important;
            align-items: center;
            .quiz__button{
                margin-right: 20px;
                >i{
                    margin-right: 7px;
                }
                &.quiz__button--published{
                    &:hover{
                        color: #00b77aa8;
                    }
                }
                &.quiz__button--draft{
                    &:hover{
                        color: #e0186ba2;;
                    }
                }
            }
            .telegram-bar{
                color: black;
                cursor: pointer;
                position: relative;
                i{
                    margin-right: 10px;
                    &.fa-chevron-down{
                        margin-left: 10px;
                        font-size: 10px;
                    }
                }
                &.active{
                    color: #00B77A;
                }
                .chat-config{
                    position: absolute;
                    bottom: -100px;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.10796);
                    width: 226px;
                    p{
                        font-size: 15px;
                        color: #000000;
                        margin: 0;
                        line-height: 30px;
                        padding: 10px 20px;
                        &:hover{
                            background-color: #b5b5b528;
                        }
                    }
                }
            }
        }
        .quiz-info{
            display: flex;
            justify-content: space-between;
            >div{
                padding: 0 15px;
                border-right: 1px solid #B5B5B5;
                &:last-child{
                    border: none;
                }
                span{
                    color: #717171;
                    font-weight: 500;
                    font-size: 15px;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .quiz-container{
        margin-top: 120px;
        .quiz{
            .quiz-body{
                .quiz-content{
                    flex-direction: column;
                    width: 50%;
                    .quiz-img{
                        margin-right: 0;
                        margin-bottom: 20px;
                    }
                }
            }
            .quiz-footer{
                height: unset;
                padding: 10px 20px;
                .quiz-info{
                    flex-direction: column;
                    >div{
                        border: none;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .quiz .quiz-body{
        flex-direction: column;
        .quiz-content{
            width: 100%;
        }
        .dropdown{
           margin: 0;
           margin-top: 20px; 
        }
    }
}
